import React from "react";
import { withAuthentication } from "../wrappers";
import moment from "moment";

{
  /* <TimeFrameControls
            showUser={false}
            callback={(q, f, start, stop) => {
              console.log(start);
              _this.setState(
                {
                  limitQuery: q,
                  timeStart: start,
                  timeStop: stop,
                },
                () => {
                  this.getRecentTasks(q, ({ data, error }) => {
                    if (error) {
                      console.error(error);
                    } else {
                      this.setState({ tasks: data });
                    }
                  });
                },
              );
            }}
          /> */
}

class TimeFrameControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limitQuery:
        "start_time_ge=" +
        moment(new Date()).startOf("month").format("YYYY-MM-DD"),
      selectedFrame: 0,
      team: this.props.team,
      selected_freelancer: this.props.team?.length ? this.props.team[0].id : 0,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleTeamMemberSelect = this.handleTeamMemberSelect.bind(this);
  }

  // componentDidMount() {
  //   this.handleSelect({ target: { value: 0 } });
  // }

  componentDidUpdate(prevprops) {
    if (this.props.team != prevprops.team) {
      this.setState({
        team: this.props.team,
        selected_freelancer: this.props.team[0].id,
      });
      let q = this.state.limitQuery;
      let f = undefined;
      if (this.props.showUser == true) {
        q += "&account_id=" + this.props.team[0].id;
        f = this.props.team[0];
      }
      this.props.callback(q, f, this.state.start, this.state.stop);
    }
  }

  mapLimitQuery(selection) {
    const now = moment();
    let start, stop;

    selection = parseInt(selection);

    //startOf / endOf functions seem to set the value of the moment object as well, so we need to initialize start and stop seperately.
    start = moment(now);
    stop = moment(now);
    switch (selection) {
      case 0: // This month
        start = start.startOf("month");
        stop = stop.endOf("month").add(1, "day");
        break;
      case 1: // Last month
        start = start.subtract(1, "months").startOf("month");
        stop = stop.endOf("month").add(1, "day");
        break;
      case 2: // This week
        start = start.startOf("week");
        break;
      case 3: // Last week
        start = start.subtract(1, "weeks").startOf("week");
        stop = stop.endOf("week").add(1, "day");
        break;
      case 4: // This year
        start = start.startOf("year");
        break;
      default:
        break;
    }

    const startStr = start.format("YYYY-MM-DD");
    const stopStr = stop.format("YYYY-MM-DD");
    const query = `start_time_ge=${startStr}&start_time_lt=${stopStr}`;

    return [startStr, stopStr, query];
  }

  // mapLimitQuery(selection) {
  //   let start = new Date();
  //   let stop = new Date();

  //   if (selection == 0) {
  //     start = moment(new Date()).startOf("month").format("YYYY-MM-DD");
  //     stop = moment(new Date()).endOf("month").format("YYYY-MM-DD");
  //   }
  //   if (selection == 1) {
  //     start = moment(new Date())
  //       .subtract(1, "months")
  //       .startOf("month")
  //       .format("YYYY-MM-DD");
  //     stop = moment(new Date())
  //       .subtract(1, "months")
  //       .endOf("month")
  //       .format("YYYY-MM-DD");
  //   }
  //   if (selection == 2) {
  //     start = moment(new Date()).startOf("week").format("YYYY-MM-DD");
  //     stop = moment(new Date()).format("YYYY-MM-DD");
  //   }
  //   if (selection == 3) {
  //     start = moment(new Date())
  //       .subtract(1, "weeks")
  //       .startOf("week")
  //       .format("YYYY-MM-DD");
  //     stop = moment(new Date())
  //       .subtract(1, "weeks")
  //       .endOf("week")
  //       .format("YYYY-MM-DD");
  //   }
  //   if (selection == 4) {
  //     start = moment(new Date()).startOf("year").format("YYYY-MM-DD");
  //     stop = moment(new Date()).format("YYYY-MM-DD");
  //   }

  //   return [start, stop, "start_time_ge=" + start + "&start_time_lt=" + stop];
  // }

  handleSelect(e) {
    let r = this.mapLimitQuery(e.target.value);
    let start = r[0];
    let stop = r[1];
    let q = r[2];
    this.setState(
      {
        limitQuery: q,
        selectedFrame: e.target.value,
        start: start,
        stop: stop,
      },
      () => {
        let f = undefined;
        if (this.props.showUser == true)
          f = this.state.team[this.state.selected_freelancer];
        this.props.callback(
          q + "&account_id=" + this.state.selected_freelancer,
          f,
          start,
          stop,
        );
      },
    );
  }

  handleTeamMemberSelect(e) {
    this.setState({
      selected_freelancer: e.target.value,
    });
    this.props.callback(
      this.state.limitQuery + "&account_id=" + e.target.value,
      this.state.team[e.target.value],
      this.state.start,
      this.state.stop,
    );
  }

  render() {
    return (
      <div className="card card-body mb-5">
        {this.props.showUser == true ? (
          <select
            className="form-select mb-2"
            onChange={this.handleTeamMemberSelect}
            value={this.state.selected_freelancer}
          >
            <option key={0} value={0}>
              Select a team member
            </option>
            {this.state.team.map((freelancer) => {
              return (
                <option key={freelancer.id} value={freelancer.id}>
                  {freelancer.name} {freelancer.surname}
                </option>
              );
            })}
          </select>
        ) : (
          <></>
        )}
        <select
          className="form-select"
          aria-label="Default select example"
          onChange={this.handleSelect}
          value={this.state.selectedFrame}
        >
          <option value={0}>This Month</option>
          <option value={1}>Last Month</option>
          <option value={2}>This Week</option>
          <option value={3}>Last Week</option>
          <option value={4}>This Year</option>
        </select>
      </div>
    );
  }
}

export default withAuthentication(TimeFrameControls);
