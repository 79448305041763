import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { DateTime } from "luxon";

import { roundTo } from "../../util/helpers";
import { useLoaderData } from "react-router-dom";


export default function TimeDonut({
  fancykeyname,
  keyname,
  height,
  colors,
  topNBuckets,
}) {
  const { entries } = useLoaderData();
  const [data, setData] = useState(null);

  const update = () => {
    // In any case, the parent sends an array of entries, either for a given user or for the whole team
    // The job is the following:
    // 1. Determine what the Doughnut will be displaying: projects, tasks, departments, etc.
    // 2. Iterate over all entries to find all unique task names, project names, etc., and aggregate their frequencies
    // 3. Retrieve the unique keys, up to a given MAX number, with the top frequencies
    // 4. Aggregate all other keys in an "other category"
    // 5. Construct the data object that will be passed to the Doughnut

    // Determine the category by which we are going to aggregate data; "project" by default
    let categoryName = keyname || "project";

    if (categoryName === "organization") {
      categoryName = "department.org";
    }

    // Initialize an empty object that will be populated with unique keys and their respective aggregated durations
    const entryDurations = {};

    // Start iterating over the array of entries passed from the parent
    entries.forEach((entry) => {
      const uniqueKey = getUniqueKey(entry, categoryName);
      const duration = calculateDuration(entry);
      entryDurations[uniqueKey] = roundTo(
        (entryDurations[uniqueKey] || 0) + duration,
        2,
      );
    });

    // Select top N buckets and calculate the "other" category if necessary
    const { topLabels, topDurations, otherDuration } = selectTopDurations(
      entryDurations,
      topNBuckets,
    );
    if (otherDuration > 0) {
      topLabels.push("other");
      topDurations.push(otherDuration);
    }

    // Update state with new data
    const data = prepareChartData(topLabels, topDurations, keyname);

    setData(data);
  };

  useEffect(() => {
    update();

    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      ArcElement,
      Title,
      Tooltip,
      Filler,
      Legend,
    );
  }, [entries]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Hours by ${fancykeyname || keyname || "project"}`,
      },
    },
  };

  const calculateDuration = (entry) => {
    const start = DateTime.fromISO(entry.start_time);
    const end = DateTime.fromISO(entry.end_time);
    const duration = end.diff(start, "hours");
    return duration.hours;
  };

  const selectTopDurations = (durations, maxCount) => {
    const entries = Object.entries(durations).sort((a, b) => b[1] - a[1]);
    const topEntries = entries.slice(0, maxCount);
    const otherEntries = (entries.length > maxCount)? entries.slice(maxCount) : [];
    const otherDuration = otherEntries.reduce(
      (sum, [, duration]) => sum + duration,
      0,
    );

    return {
      topLabels: topEntries.map(([key]) => key),
      topDurations: topEntries.map(([, duration]) => duration),
      otherDuration,
    };
  };

  const getUniqueKey = (entry, categoryName) => {
    let uniqueKey = entry[categoryName]?.name;

    const categoryToArray = categoryName.split(".");
    if (categoryToArray.length !== 1) {
      const oneLevelDown = categoryToArray[0];
      const twoLevelsDown = categoryToArray[1];
      uniqueKey = entry[oneLevelDown][twoLevelsDown]["name"];
    }

    return uniqueKey;
  };

  const prepareChartData = (labels, data, key) => {
    return {
      labels,
      datasets: [
        {
          fill: true,
          label: `Hours by ${key || "project"}`,
          data,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: colors.slice(0, labels.length),
        },
      ],
    };
  };

  return data && <Doughnut options={options} data={data} height={height} />;
}
