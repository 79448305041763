import { CSSTransition } from "react-transition-group";

export default function TimerButton({ onClick, btnClass, label, type, style, name, value }) {
  return (
    <CSSTransition timeout={500} classNames="timer-btn-anim">
      <div className="timer-btn-anim">
        <button
          type={type}
          name={name}
          value={value}
          className={`btn btn-${btnClass} btn-timer`}
          onClick={onClick}
          style={style}
        >
          {label}
        </button>
      </div>
    </CSSTransition>
  );
}
