import { useSelector } from "react-redux";
import config from "../../config";
import { useLoaderData } from "react-router-dom";
import { toSymbol } from "../../util/helpers";
import { useEffect } from "react";

export default function InvoiceInfo({ now, timeStart, timeStop }) {
  const user = useSelector((state) => state.auth.user);
  const { invhash, invoiceNumber, totalCost } = useLoaderData();
  const symbol = toSymbol(user.currency);

  return (
    <div className="row mb-3">
      <div className="col-md-9">
        <h3 className="">
          {user.companyAlias
            ? user.companyAlias
            : user.name + " " + user.surname}
        </h3>
        <span>
          <b>Name: </b>
          {user.name + " " + user.surname}
        </span>
        <br />
        <span>
          {" "}
          <b>Address:</b> {user.fullAddress}{" "}
        </span>
        <br />
        <span>
          {" "}
          <b>Billed to:</b> {config.orgName}{" "}
        </span>
      </div>
      <div className="col-md-3">
        <span>
          {" "}
          <b>Invoice Number:</b> {invoiceNumber}{" "}
        </span>
        <br />
        <span>
          {" "}
          <b>Invoice Hash:</b> {invhash}{" "}
        </span>
        <br />
        <span>
          {" "}
          <b>Issue Date:</b> {now.toFormat("yyyy-MM-dd")}{" "}
        </span>
        <br />
        <span>
          {" "}
          <b>Time Period: </b>
          <div className="mx-2">
            <b>Start:</b> {timeStart.toFormat("yyyy-MM-dd HH:mm (ZZ)")} <br />
            <b>End: </b>{" "}
            {timeStop.minus({ days: 1 }).toFormat("yyyy-MM-dd HH:mm")}
          </div>
        </span>
        <br />
        <span className="print-only">
          {" "}
          <b>Total:</b> {symbol + totalCost}
        </span>
        <br />
      </div>
    </div>
  );
}
