import { get, patch, post } from "../util/api";

// ---TASKS---
export async function loadMyRecentTasks(limit) {
  try {
    const res = await get(
      `/api/entry/my?join=service,task,project,department,department.org,service&limit=250&task_nt=null&order=start_time:desc&${limit || ""}`,
    );

    return res.entities;
  } catch (error) {
    console.error("Error loading tasks:", error);
  }
}

export async function loadRecentTasks(limit) {
  try {
    const res = await get(
      "/api/entry/search?join=service,task,project,department,department.org,service&limit=250&task_nt=null&order=start_time:desc&" +
        (limit ? limit : ""),
    );
    return res.entities;
  } catch (error) {
    console.error(error);
  }
}



// ---ORGANIZATIONS---
export async function loadOrganizations() {
  try {
    const res = await get("/api/org/search?join=departments");
    let orgs = {};
    res.entities.forEach((org) => {
      let departments = {};
      org.departments.forEach((department) => {
        departments[department.id] = department;
      });
      org.departments = departments;
      orgs[org.id] = org;
    });
    return orgs;
  } catch (error) {
    console.error("Failed to load organizations:", error);
    // TODO: Error handling
  }
}

export function loadOrganizationsWithDepartments() {
  get("/api/org/search?join=departments").then((res) => {
    let orgs = {};
    res.entities.forEach((org) => {
      let departments = {};
      org.departments.forEach((department) => {
        departments[department.id] = department;
      });
      org.departments = departments;
      orgs[org.id] = org;
    });
    this.setState({
      orgs: orgs,
    });
  });
}

export function setPrintParams() {
  const style = document.createElement("style");
  style.innerHTML = `
    @media print {
      @page {
        size: tabloid landscape;
      }
    }
  `;
  style.id = "page-orientation";
  document.head.appendChild(style);
}

export function removePrintParams() {
  const styleElement = document.getElementById("page-orientation");
  if (styleElement) {
    document.head.removeChild(styleElement);
  }
}

// ---USER---
export function getUserInfo() {
  get("/api/account/my").then((res) => {
    this.setState({
      user: res.entities[0],
    });

    this.props.login(res.entities[0]);
  });
}

export async function getPaymentInfo() {
  try {
    const res = await get("/api/service_type/my");

    const services = res.entities.reduce((acc, service) => {
      acc[service.id] = service;
      return acc;
    }, {});

    this.setState({
      services,
    });
  } catch (error) {
    console.error("Failed to fetch payment info:", error);
  }
}

export async function updateProfile(user) {
  try {
    const response = await patch("/api/account", user);
    return response;
    // let newUser = {
    //   ...this.state.user,
    //   ...result.entity,
    // };
    // this.props.login(newUser);
  } catch (error) {
    throw new Error(error);
  }
}

// ---TEAM---
export async function getTeamInfo() {
  try {
    const team = await get("/api/team/");
    //sort team alphabetically on name
    team.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    return team;
  } catch (error) {
    throw new Error(error);
  }
}

// ---SERVICES---
export async function getPaymentInfoHourly() {
  try {
    const res = await get("/api/service_type/my?is_hourly=1");
    let services = {};
    res.entities.forEach((service) => {
      services[service.id] = service;
    });

    return services;
  } catch (error) {
    console.error("Error fetching payment info:", error);
    // TODO: Error handling
  }
}

export async function submitNewService(payload) {
  try {
    // payload["account_id"] = userId;
    const res = await post("/api/user/service_type", payload);
    return res;
  } catch (error) {
    console.error("Failed to submit new service:", error);
  }
}

// TODO: PATCH changes to database if they differ from initial state | PATCH is currenlty not set up
export async function updateServices(payload, userId) {
  try {
    payload["account_id"] = userId;
    await patch("/api/service_type", payload);
  } catch (error) {
    console.error("Failed to update services:", error);
  }
}

// ---USER---
export async function userLogin(username, password) {
  try {
    const response = await post("/auth/login", {
      email: username,
      password: password,
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
}

export async function userLogout() {
  try {
    await post("/auth/logout", {});
  } catch (error) {
    throw new Error(error);
  }
}

export async function changePwd(oldPassword, newPassword) {
  try {
    const response = await post("/api/user/change-password", {
      oldPassword,
      newPassword,
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
}

// ---UTILITIES---
export function toSymbol(currency) {
  if (currency === 0) return "$";
  if (currency === 1) return "€";
  if (currency === 2) return "£";
}

export function roundTo(num, decimalPlaces) {
  return Number(num.toFixed(decimalPlaces));
}

export function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
