import React, { useEffect, useState } from "react";
import { get } from "../util/api";
import {
  HourHistogram,
  TimeDonut,
  TotalHoursTable,
  OldTasksTable,
  CostDonut,
} from "../components";
import "../assets/print.css";
import { DateTime } from "luxon";
import { redirect, useLoaderData, useSubmit } from "react-router-dom";
import { useSelector } from "react-redux";
import InvoiceSelect from "../components/Invoice/InvoiceSelect";
import DonutFlavorPicker from "../components/Invoice/DonutFlavorPicker";
import Calendar from "react-calendar";
import {
  getTeamInfo,
  setPrintParams,
  removePrintParams,
} from "../util/helpers";
import CostBreakdownTable from "../components/Invoice/CostBreakdownTable";
import TimeBreakdownTable from "../components/Invoice/TimeBreakdownTable";

const topNBuckets = 6;
const colors = [
  "#FFD700", // Gold
  "#FFA07A", // Light Salmon
  "#FF69B4", // Hot Pink
  "#87CEEB", // Sky Blueh
  "#98FB98", // Pale Green
  "#9370DB", // Medium Purple
  "#FFE4B5", // Moccasin
  "#20B2AA", // Light Sea Green
  "#FF6347", // Tomato
  "#40E0D0", // Turquoise
  "#FF8C00", // Dark Orange
  "#BA55D3", // Medium Orchid
  "#00FA9A", // Medium Spring Green
  "#FF4500", // Orange Red
  "#AFEEEE", // Pale Turquoise
  "#FFDAB9", // Peachpuff
  "#00CED1", // Dark Turquoise
  "#DDA0DD", // Plum
  "#8FBC8F", // Dark Sea Green
  "#FFB6C1", // Light Pink
];

const getEntries = async (team, startTime, stopTime) => {
  const now = DateTime.now();
  const startOfMonth = now.startOf("month");

  let localizedStartTime = startTime ? DateTime.fromISO(startTime).toISO() : startOfMonth.toISO();
  let localizedStopTime = stopTime ? DateTime.fromISO(stopTime).toISO() : now.toISO();
  const limitQuery = `start_time=${startTime ? localizedStartTime : startOfMonth.toISO()}${stopTime ? "&end_time=" + localizedStopTime : ""}`;

  const entriesByUser = await get(`/api/team/tasks?${limitQuery}`);

  const transformedTeam = team.reduce((acc, currentEntry) => {
    acc[currentEntry.id] = currentEntry;
    return acc;
  }, {});

  Object.keys(entriesByUser).forEach((freelancer) => {
    entriesByUser[freelancer].forEach((entry) => {
      entry.freelancer = transformedTeam[freelancer];
    });
  });

  const allTeamEntries = [...Object.values(entriesByUser).flat()];
  allTeamEntries.sort(
    (a, b) => DateTime.fromISO(b.start_time) - DateTime.fromISO(a.start_time),
  );

  return allTeamEntries;
};

const getAvailabilityMultiplier = (selectedFrame) => {
  let multiplier = 1;

  if (selectedFrame === 0 || selectedFrame === 1) {
    multiplier *= 4;
  } else if (selectedFrame === 4) {
    multiplier *= 52;
  }

  return multiplier;
};

export const teamReportLoader = async ({ request }) => {
  const url = new URL(request.url);
  const startTime = url.searchParams.get("start_time");
  const stopTime = url.searchParams.get("end_time");
  const selectedFrame = url.searchParams.get("selected_frame");
  
  try {
    const team = await getTeamInfo();
    const entries = await getEntries(team, startTime, stopTime);
    const availabilityMultiplier = getAvailabilityMultiplier(parseInt(selectedFrame));

    return {
      team,
      entries,
      selectedFrame: selectedFrame || 0,
      availabilityMultiplier,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const teamReportAction = async ({ request }) => {
  const formData = await request.formData();
  const startTime = formData.get("startTime");
  const stopTime = formData.get("stopTime");
  const selectedFrame = formData.get("selectedFrame");
  const params = new URLSearchParams();
  if (startTime) params.append("start_time", startTime);
  if (stopTime) params.append("end_time", stopTime);
  if (selectedFrame) params.append("selected_frame", selectedFrame);

  const updatedUrl = `/team-report?${params.toString()}`;

  return redirect(updatedUrl);
};


export default function TeamReport() {
  const { entries } = useLoaderData();
  const submit = useSubmit();
  const user = useSelector((state) => state.auth.user);
  const url = new URL(window.location.href);
  let start_time = DateTime.fromISO(url.searchParams.get("start_time")).toJSDate();
  let stop_time = DateTime.fromISO(url.searchParams.get("end_time")).toJSDate();
  let selectedFrame = url.searchParams.get("selected_frame") || 0;
  let [donutFlavor, setDonutFlavor] = useState("Time");

  useEffect(() => {
    setPrintParams();

    return () => {
      removePrintParams();
    };
  }, []);

  const setCustomStart = (date) => {
    const startTime = date.toISOString()
    const url = new URL(window.location.href);
    const formData = new FormData();
    const stopTime = DateTime.fromISO(url.searchParams.get("end_time")).toISO();
    window.history.replaceState(null, "", url.toString());
    formData.append("startTime", startTime);
    formData.append("stopTime", stopTime);
    formData.append("selectedFrame", 6);
  
    submit(formData, {
      method: "post",
      action: "/team-report",
    });
  };
  
  const setCustomStop = (date) => {
    const stopTime = date.toISOString()
    const url = new URL(window.location.href);
    const formData = new FormData();
    const startTime = DateTime.fromISO(url.searchParams.get("start_time")).toISO();
    window.history.replaceState(null, "", url.toString());
    formData.append("startTime", startTime);
    formData.append("stopTime", stopTime);
    formData.append("selectedFrame", 6);
  
    submit(formData, {
      method: "post",
      action: "/team-report",
    });
  };

  
  const mapLimitQuery = (selection) => {
    const now = DateTime.now();
    let start, stop;
    let customTimeSelectionEnabled = false;
    // The .plus() is a workaround, the api needs to be fixed further

    switch (selection) {
      case 0: // This month
        start = now.startOf("month");
        stop = now.endOf("month").plus({ days: 1 });
        break;
      case 1: // Last month
        start = now.minus({ months: 1 }).startOf("month");
        stop = now.minus({ months: 1 }).endOf("month").plus({ days: 1 });
        break;
      case 2: // This week (Monday to Sunday)
        start = now.startOf("week");
        stop = now.endOf("week").plus({ days: 1 });
        break;
      case 3: // Last week (Monday to Sunday)
        start = now.minus({ weeks: 1 }).startOf("week");
        stop = now.minus({ weeks: 1 }).endOf("week").plus({ days: 1 });
        break;
      case 4: // This year
        start = now.startOf("year");
        stop = now.plus({ days: 1 });
        break;
      case 5: // This week (Friday to Thursday)
        start = now.startOf("week").minus({ weeks: 1 }).plus({ days: 4 });
        stop = now.startOf("week").plus({ days: 4 });
        break;
      case 6:

        start = now.startOf("month");
        stop = now.endOf("month").plus({ days: 1 });
        customTimeSelectionEnabled = true;
        break;
      default:
        start = now;
        stop = now;
    }

    return { start, stop ,customTimeSelectionEnabled};
  };

  const handleSelect = (e) => {
    const selectedValue = parseInt(e.target.value);
    let { start, stop, customTimeSelectionEnabled } = mapLimitQuery(selectedValue);
    const formData = new FormData();
    formData.append("startTime", start.toISODate());
    formData.append("stopTime", stop.toISODate());
    formData.append("selectedFrame", selectedValue);

    submit(formData, {
      method: "post",
      action: "/team-report",
    });
  };

  const handleDonutFlavor = (flavor) => {
    return setDonutFlavor(flavor);
  }
  
  //
  let DonutFlavor;
  let BreakdownFlavor;
  if(user.role>=2){
    DonutFlavor = (donutFlavor == "Time") ? TimeDonut : CostDonut;
    BreakdownFlavor = (donutFlavor == "Time") ? TimeBreakdownTable : CostBreakdownTable;
  }
  else {
    DonutFlavor = TimeDonut;
    BreakdownFlavor = TimeBreakdownTable;
  }
    

  return (
    <div className="container">
      <div className="buffer-5vh" />
      <button
        disabled={!entries.length}
        className="btn btn-success mb-3"
        onClick={() => window.print()}
      >
        Save as PDF
      </button>
      {user.role >= 2 && <DonutFlavorPicker handleFlavor={handleDonutFlavor} />}

      <InvoiceSelect handleSelect={handleSelect} showUser={false} />
      {selectedFrame==6 && (
          <div className="card card-body center">
          <div className="row center">
            <div className="col">
              <Calendar
                value={start_time}
                maxDate={stop_time}
                onChange={setCustomStart}
              />
            </div>
            <div className="col">
              <Calendar
                value={stop_time}
                onChange={setCustomStop}
              />
            </div>
          </div>
          </div>
        )}
      {entries.length ? (
        <>
          <div className="row">
            <div className="card card-body">
              <div className="col"></div>
              <div className="row">
                <div className="col">
                  <div
                    className="card card-body"
                    style={{ height: "400px", maxHeight: "400px" }}
                  >
                    <HourHistogram height="400px" />
                  </div>
                </div>
              </div>

                <div className="card card-body">
                  <h2>Breakdowns by cost</h2>
                  <div className="row">
                    <div className="col">
                      <div className="card card-body">
                      <DonutFlavor
                          colors={colors}
                          keyname={"project"}
                          fancykeyname={"project"}
                          height="250px"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body">
                        <DonutFlavor
                          colors={colors}
                          keyname={"task"}
                          fancykeyname={"task"}
                          height="250px"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body">
                        <DonutFlavor
                          colors={colors}
                          keyname={"freelancer"}
                          fancykeyname={"freelancer"}
                          height="250px"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="card card-body">
                        <DonutFlavor
                          colors={colors}
                          keyname={"department"}
                          fancykeyname={"department"}
                          height="250px"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body">
                        <DonutFlavor
                          colors={colors}
                          keyname={"organization"}
                          fancykeyname={"organization"}
                          height="250px"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body">
                        <div className="">
                        <TotalHoursTable
                          colors={colors}
                          keyname={"freelancer"}
                          height="250px"
                        />
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="buffer-5vh" />
          <div className="row">
            <div className="card card-body">
              <BreakdownFlavor
                keyname={"organization"}
              />
            </div>
          </div>
          <div className="row">
            <div className="card card-body">
              <BreakdownFlavor
                keyname={"department"}
              />
            </div>
          </div>
          <div className="row">
            <div className="card card-body">
              <BreakdownFlavor
                keyname={"freelancer"}
              />
            </div>
          </div>
          <div className="row">
            <div className="card card-body">
              <BreakdownFlavor
                keyname={"project"}
              />
            </div>
          </div>
          <div className="buffer-5vh" />
          <div className="row">
            <div className="card card-body">
              <OldTasksTable
                entries={entries}
                date={DateTime.now()}
                entryType="team-task"
              />
            </div>
          </div>
        </>
      ) : (
        <span>No recorded activity for the selected period.</span>
      )}
    </div>
  );
}
