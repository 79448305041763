export const storeTaskName = (taskName) => ({
  type: "TASKNAME",
  payload: taskName,
});

export const storeTaskId = (taskId) => ({
  type: "TASKID",
  payload: taskId,
});

export const storeProjectName = (projectName) => ({
  type: "PROJECTNAME",
  payload: projectName,
});

export const storeProjectId = (projectId) => ({
  type: "PROJECTID",
  payload: projectId,
});

export const storeDepartmentId = (departmentId) => ({
  type: "DEPARTMENTID",
  payload: departmentId,
});

export const storeServiceId = (serviceId) => ({
  type: "SERVICEID",
  payload: serviceId,
});

export const storeOrgId = (orgId) => ({
  type: "ORGID",
  payload: orgId,
});
