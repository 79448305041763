import TimerButton from "./TimerButton";
import { TransitionGroup } from "react-transition-group";

export default function ButtonsTimer({
  timerStatus,
  manualStatus,
  openModal,
  manualMode,
}) {
  return (
    <div className="col btn-group anim-window">
      <TransitionGroup>
        {timerStatus && !manualStatus ? (
          <>
            <TimerButton
              btnClass="danger"
              label="Cancel"
              name="intent"
              value="cancel"
              type="submit"
            />
            <TimerButton
              btnClass="primary"
              name="intent"
              value="post"
              label="Stop"
              type="submit"
            />
          </>
        ) : manualStatus ? (
          <div className="test-flex-col">
            <div>
              <TimerButton
                onClick={openModal}
                btnClass="primary"
                label="Edit Time"
                type="button"
              />
              <TimerButton
                btnClass="success"
                label="Confirm"
                name="intent"
                value="postManual"
                type="submit"
              />
            </div>
            <TimerButton
              onClick={manualMode}
              btnClass="danger"
              label="Exit manual mode"
              type="button"
              style={{ width: "100%" }}
            />
          </div>
        ) : (
          <>
            <TimerButton
              onClick={manualMode}
              btnClass="warning"
              label="Manual"
              type="button"
            />
            <TimerButton
              btnClass="primary"
              name="intent"
              value="start"
              label="Start"
              type="submit"
            />
          </>
        )}
      </TransitionGroup>
    </div>
  );
}
