import { useEffect, useState } from "react";
import { get } from "../../util/api";

import DateModal from "./DateModal";
import ActiveTimer from "./ActiveTimer";
import AutocompleteInput from "./AutoCompleteInput";
import SelectInput from "./SelectInput";
import ButtonsTimer from "./ButtonsTimer";
import {
  Form,
  useActionData,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  storeDepartmentId,
  storeOrgId,
  storeProjectId,
  storeProjectName,
  storeServiceId,
  storeTaskId,
  storeTaskName,
} from "../../actions/task";

export default function CurrentTask() {
  const { timerRes, orgs, services } = useLoaderData();
  const { state: formState } = useLocation();
  const errors = useActionData();
  const {
    orgId,
    departmentId,
    serviceId,
    taskName,
    taskId,
    projectName,
    projectId,
  } = useSelector((state) => state.task);

  const dispatch = useDispatch();

  const [state, setState] = useState({
    projectSuggestions: [],
    taskSuggestions: [],

    manualStatus: false,
    manualStartTime: new Date(),
    manualEndTime: new Date(),

    timerStatus: timerRes.timerStatus,
    startTime: timerRes.startTime,
  });

  useEffect(() => {
    if (!orgId) {
      dispatch(storeOrgId(parseInt(Object.values(orgs)[0].id)));
    }

    if (!departmentId) {
      dispatch(
        storeDepartmentId(
          parseInt(Object.values(Object.values(orgs)[0].departments)[0].id),
        ),
      );
    }

    if (!serviceId) {
      dispatch(storeServiceId(parseInt(Object.values(services)[0].id)));
    }
  }, []);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      timerStatus: timerRes.timerStatus,
      startTime: timerRes.startTime,
      manualStatus: false,
    }));
  }, [formState]);

  // ---UI STATE--
  const manualMode = () => {
    setState((prevState) => ({
      ...prevState,
      manualStatus: !prevState.manualStatus,
    }));
  };

  const openModal = () => {
    setState((prevState) => ({
      ...prevState,
      modalVisible: true,
    }));
  };

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      modalVisible: false,
    }));
  };

  // ---CONTROLLED INPUTS---
  const handleTask = async (e) => {
    const { value } = e.target;

    dispatch(storeTaskName(value));

    if (value !== "0") {
      dispatch(storeTaskId(0));
    }

    if (value === "") {
      setState((prevState) => ({
        ...prevState,
        taskSuggestions: [],
      }));
      return;
    }

    const res = await get(`/api/team/autocomplete/task?name=${value}`);

    setState((prevState) => ({
      ...prevState,
      taskSuggestions: res,
    }));
  };

  const handleProject = async (e) => {
    const { value } = e.target;

    dispatch(storeProjectName(value));

    if (value !== "0") {
      dispatch(storeProjectId(0));
    }

    if (value === "") {
      setState((prevState) => ({
        ...prevState,
        projectSuggestions: [],
      }));
      return;
    }

    const res = await get(`/api/team/autocomplete/project?name=${value}`);

    setState((prevState) => ({
      ...prevState,
      projectSuggestions: res,
    }));
  };

  const onProjectSuggestionClick = (suggestion) => {
    dispatch(storeProjectId(suggestion.id));
    dispatch(storeProjectName(suggestion.name));

    setState((prevState) => ({
      ...prevState,
      projectSuggestions: [],
    }));
  };

  const onTaskSuggestionClick = (suggestion) => {
    dispatch(storeTaskId(suggestion.id));
    dispatch(storeTaskName(suggestion.name));

    setState((prevState) => ({
      ...prevState,
      taskSuggestions: [],
    }));
  };

  const cancelAutocomplete = (e) => {
    const { id } = e.target;

    if (id === "project" && state.projectSuggestions.length) {
      setState((prevState) => ({
        ...prevState,
        projectSuggestions: [],
      }));
    }

    if (id === "task" && state.taskSuggestions.length) {
      setState((prevState) => ({
        ...prevState,
        taskSuggestions: [],
      }));
    }
  };

  const setDepartment = (e) => {
    dispatch(storeDepartmentId(parseInt(e.target.value)));
  };

  const setOrg = (e) => {
    dispatch(storeOrgId(parseInt(e.target.value)));
  };

  const setService = (e) => {
    dispatch(storeServiceId(parseInt(e.target.value)));
  };

  const setManualStartTime = (time) => {
    let newStartTime = new Date(state.manualStartTime.getTime());

    if (typeof time === "string") {
      const [hours, minutes] = time.split(":").map(Number);
      newStartTime.setHours(hours);
      newStartTime.setMinutes(minutes);
      newStartTime.setSeconds(0);
      newStartTime.setMilliseconds(0);
    } else if (time instanceof Date) {
      newStartTime.setFullYear(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
      );
    }

    setState((prevState) => ({
      ...prevState,
      manualStartTime: newStartTime,
    }));
  };

  const setManualEndTime = (time) => {
    let newEndTime = new Date(state.manualEndTime.getTime());
    if (typeof time === "string") {
      const [hours, minutes] = time.split(":").map(Number);
      newEndTime.setHours(hours);
      newEndTime.setMinutes(minutes);
      newEndTime.setSeconds(0);
      newEndTime.setMilliseconds(0);
    } else if (time instanceof Date) {
      newEndTime.setFullYear(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
      );
    }

    setState((prevState) => ({
      ...prevState,
      manualEndTime: newEndTime,
    }));
  };

  if (!orgId || !departmentId || !serviceId) {
    return <div>Loading...</div>;
  }

  return (
    <Form
      method="post"
      state={{ refresh: true }}
      className="row g-3 current-task"
    >
      <DateModal
        modalVisible={state.modalVisible}
        closeModal={closeModal}
        manualStartTime={state.manualStartTime}
        setManualStartTime={setManualStartTime}
        manualEndTime={state.manualEndTime}
        setManualEndTime={setManualEndTime}
      />
      <input
        hidden
        readOnly
        name="manualStartTime"
        value={state.manualStartTime.toISOString()}
      />
      <input
        hidden
        readOnly
        name="manualEndTime"
        value={state.manualEndTime.toISOString()}
      />
      <AutocompleteInput
        inputType="project"
        name="projectName"
        value={projectName}
        suggestions={state.projectSuggestions}
        onInputChange={handleProject}
        onSuggestionClick={onProjectSuggestionClick}
        onClick={cancelAutocomplete}
        submittedWithErrors={errors?.projectName ? true : false}
      />

      <input hidden readOnly name="projectId" value={projectId} />

      <AutocompleteInput
        inputType="task"
        name="taskName"
        value={taskName}
        suggestions={state.taskSuggestions}
        onInputChange={handleTask}
        onSuggestionClick={onTaskSuggestionClick}
        onClick={cancelAutocomplete}
        submittedWithErrors={errors?.taskName ? true : false}
      />

      <input hidden readOnly name="taskId" value={taskId} />

      <SelectInput
        ariaLabel="Department"
        onChange={setDepartment}
        name="departmentId"
        value={departmentId}
        options={Object.values(orgs[orgId].departments)}
      />

      <SelectInput
        ariaLabel="Org"
        onChange={setOrg}
        name="orgId"
        value={orgId}
        options={Object.values(orgs)}
      />

      <SelectInput
        ariaLabel="Service"
        onChange={setService}
        name="serviceId"
        value={serviceId}
        options={Object.values(services)}
      />

      <ActiveTimer
        startTime={state.startTime}
        manualStatus={state.manualStatus}
        manualStartTime={state.manualStartTime}
        manualEndTime={state.manualEndTime}
      />

      <ButtonsTimer
        timerStatus={state.timerStatus}
        manualStatus={state.manualStatus}
        openModal={openModal}
        manualMode={manualMode}
      />
    </Form>
  );
}
