import { NavLink, Outlet } from "react-router-dom";

const navItems = [
  { to: "details", text: "Profile Details" },
  { to: "services", text: "Services" },
  { to: "changepwd", text: "Change Password" },
  { to: "team", text: "Team" },
  { to: "theme", text: "Theme" },
];

export default function Profile() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3" style={{ borderRight: "solid 3 px #ccc" }}>
          <div className="card card-body">
            <ul className="nav nav-pills flex-column mb-auto">
              {navItems.map((item) => (
                <li className="nav-item" key={item.to}>
                  <NavLink
                    to={item.to}
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    end
                  >
                    {item.text}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col">
          <Outlet />
        </div>
      </div>
    </div>
  );
}