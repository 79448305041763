import { useState, useEffect } from "react";
import { DateTime } from "luxon";

export default function ActiveTimer({
  startTime,
  manualStatus,
  manualStartTime,
  manualEndTime,
}) {
  const [currentTime, setCurrentTime] = useState(DateTime.now());

  useEffect(() => {
    // This should solve the potential timing discrepancies caused by the event loop
    if (!manualStatus && startTime) {
      const start = DateTime.fromISO(startTime);
      const updateTimer = () => {
        const now = DateTime.now();
        const elapsed = now.diff(start, "milliseconds").milliseconds;
        const nextUpdate = 1000 - (elapsed % 1000);

        setCurrentTime(now);
        setTimeout(updateTimer, nextUpdate);
      };
      updateTimer();
      return () => clearTimeout(updateTimer);
    }
  }, [manualStatus, startTime]);

  const formatTimeDiff = () => {
    if (!startTime) return "00:00:00";
    const start = DateTime.fromISO(startTime);
    const diff = currentTime.diff(start, ["hours", "minutes", "seconds"]);
    return diff.toFormat("hh:mm:ss");
  };

  return (
    <div className="col-sm-1 timer center" id="active-timer">
      {!manualStatus && <span>{formatTimeDiff()}</span>}
      {manualStatus && (
        <div className="test-flex-col">
          <div className="test-flex-col-2">
            <span>
              <em>Start:</em>
            </span>{" "}
            <span>
              {DateTime.fromJSDate(manualStartTime).toFormat("yy-LL-dd HH:mm")}
            </span>
          </div>
          <div className="test-flex-col-2">
            <span>
              <em>End:</em>
            </span>{" "}
            <span>
              {DateTime.fromJSDate(manualEndTime).toFormat("yy-LL-dd HH:mm")}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
