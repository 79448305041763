import React, { useEffect, useState } from "react";

import "moment-timezone";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import "../../scss/clock-fix.scss";
import "../../scss/calendar-fix.scss";

import EditForm from "./EditForm";
// import { withAuthentication } from "../../wrappers";
import { DateTime, Duration } from "luxon";
// import withRouterFunctionality from "../../wrappers/withRouterFunctionality";
import { Form, useLoaderData } from "react-router-dom";
// import { useDispatch } from "react-redux";

// function convertKeysToCamelCase(obj) {
//   const newObj = {};
//   Object.keys(obj).forEach((key) => {
//     const camelCaseKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
//     newObj[camelCaseKey] = obj[key];
//   });
//   return newObj;
// }

export default function OldTask({ entry }) {
  const { orgs } = useLoaderData();

  const [state, setState] = useState({
    startTime: new Date(entry.start_time),
    endTime: new Date(entry.end_time),

    taskName: entry.task.name,
    taskId: entry.task.id,
    projectName: entry.project?.name,
    projectId: entry.project?.id,
    departmentId: entry.department.id,
    departmentName: entry.department.name,
    orgId: entry.department.org.id,
    orgName: entry.department.org.name,

    timeClassNamesa: "col-sm-2",
    timeClassNamesb: "col-sm-1",
  });

  const [isEdited, setIsEdited] = useState(false)

  useEffect(() => {
    setIsEdited(false);
  }, [entry]);

  const getInitialStateFromProps = () => {
    const { start_time, end_time } = entry;
    const startTime = new Date(start_time);
    const endTime = new Date(end_time);

    return {
      startTime,
      endTime,

      taskName: entry.task.name,
      taskId: entry.task.id,
      projectName: entry.project.name,
      projectId: entry.project.id,
      departmentId: entry.department.id,
      departmentName: entry.department.name,
      orgId: entry.department.org.id,
      orgName: entry.department.org.name,

      timeClassNamesa: "col-sm-2",
      timeClassNamesb: "col-sm-1",
    };
  };

  const cancel = () => {
    setState(getInitialStateFromProps());
    setIsEdited(false);
  };

  const onFocus = () => {
    setIsEdited(true)
  };

  const handleChange = (e) => {
    const { id, value, name } = e.target;

    setState((prevState) => {
      let newState = { ...prevState };

      newState[name] = value;

      if (name === "projectName" && value !== entry.project.name) {
        newState.projectId = 0;
      } else if (name === "projectName" && value === entry.project.name) {
        newState.projectId = entry.project.id;
      }

      if (name === "taskName" && value !== entry.task.name) {
        newState.taskId = 0;
      } else if (name === "taskName" && value === entry.task.name) {
        newState.taskId = entry.task.id;
      }

      if (id === "department") {
        newState.departmentName = orgs[state.orgId].departments[value].name;
      }

      if (id === "org") {
        newState.orgName = orgs[value].name;
      }

      return newState;
    });
  };

  const setStartTime = (time) => {
    let startTime = new Date(state.startTime.getTime());

    if (typeof time === "string") {
      const [hours, minutes] = time.split(":").map(Number);
      startTime.setHours(hours);
      startTime.setMinutes(minutes);
      startTime.setSeconds(0);
      startTime.setMilliseconds(0);
    } else if (time instanceof Date) {
      startTime.setFullYear(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
      );
    }

    setState({
      ...state,
      startTime,
    });
  };

  const setEndTime = (time) => {
    let endTime = new Date(state.endTime.getTime());
    if (typeof time === "string") {
      const [hours, minutes] = time.split(":").map(Number);
      endTime.setHours(hours);
      endTime.setMinutes(minutes);
      endTime.setSeconds(0);
      endTime.setMilliseconds(0);
    } else if (time instanceof Date) {
      endTime.setFullYear(time.getFullYear(), time.getMonth(), time.getDate());
    }

    setState({
      ...state,
      endTime,
    });
  };

  useEffect(() => {
    if (entry.is_manual) {
      setState({ ...state, timeClassNamesa: "col-sm-2 bg-danger-soft" });
      setState({ ...state, timeClassNamesb: "col-sm-1 bg-danger-soft" });
    }
    if (entry.is_edited) {
      setState({ ...state, timeClassNamesa: "col-sm-2 bg-warning-soft" });
      setState({ ...state, timeClassNamesb: "col-sm-1 bg-warning-soft" });
    }
  }, [entry]);

  return (
    <Form
      method="post"
      className="row g-3 old-task"
      onFocus={onFocus}
    >
      <input type="text" name="entryId" value={entry.id} hidden readOnly />
      <div className="segment col-sm-2">
        <input
          type="text"
          className="form-control"
          placeholder="Project"
          aria-label="project"
          name="projectName"
          id="project"
          required
          value={state.projectName}
          onChange={handleChange}
        />
        <input
          type="text"
          name="projectId"
          value={state.projectId}
          hidden
          readOnly
        />
      </div>
      <div className="segment col-md-2">
        <input
          type="text"
          className="form-control"
          placeholder="Task"
          aria-label="task"
          name="taskName"
          id="task"
          required
          value={state.taskName}
          onChange={handleChange}
        />
        <input type="text" name="taskId" value={state.taskId} hidden readOnly />
      </div>
      <div className="segment col-sm">
        <input
          name="departmentName"
          value={state.departmentName}
          hidden
          readOnly
        />
        <select
          className="form-select"
          aria-label="Department"
          id="department"
          name="departmentId"
          onChange={handleChange}
          value={state.departmentId}
        >
          {Object.values(orgs[state.orgId].departments).map((dept) => (
            <option value={dept.id} key={dept.id}>
              {dept.name}
            </option>
          ))}
        </select>
      </div>
      <div className="segment col-sm">
        <input name="orgName" value={state.orgName} hidden readOnly />
        <select
          className="form-select"
          aria-label="Org"
          id="org"
          name="orgId"
          onChange={handleChange}
          value={state.orgId}
        >
          {Object.keys(orgs).map((id) => (
            <option value={id} key={id}>
              {orgs[id].name}
            </option>
          ))}
        </select>
      </div>
      <div className={"segment " + state.timeClassNamesa}>
        {DateTime.fromISO(entry.start_time).toFormat("LLLL d, yyyy h:mm a")}
      </div>
      <div className={"segment " + state.timeClassNamesa}>
        {DateTime.fromISO(entry.end_time).toFormat("LLLL d, yyyy h:mm a")}
      </div>
      <div className={"segment " + state.timeClassNamesb}>
        {Duration.fromMillis(
          DateTime.fromISO(entry.end_time)
            .diff(DateTime.fromISO(entry.start_time))
            .toMillis(),
        ).toFormat("hh:mm:ss")}
      </div>
      <div className={!isEdited ? "hidden" : ""}>
        <TransitionGroup>
          <CSSTransition timeout={500} classNames="oldtask-edit-anim">
            <EditForm
              onCancel={cancel}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              startTime={state.startTime}
              endTime={state.endTime}
            />
          </CSSTransition>
        </TransitionGroup>
      </div>
    </Form>
  );
}

// export default withRouterFunctionality(withAuthentication(OldTask));

// class OldTask extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = this.getInitialStateFromProps(this.props.entry);
//   }

//   componentDidUpdate(prevProps) {
//     if (prevProps.state !== this.props.state) {
//       this.setState({ isEdited: false });
//     }
//   }

//   getInitialStateFromProps = (taskObj) => {
//     const { start_time, end_time } = taskObj;
//     const startTime = new Date(start_time);
//     const endTime = new Date(end_time);

//     return {
//       startTime,
//       endTime,

//       taskName: this.props.entry.task.name,
//       projectName: this.props.entry.project.name,
//       departmentId: this.props.entry.department.id,
//       departmentName: this.props.entry.department.name,
//       orgId: this.props.entry.department.org.id,
//       orgName: this.props.entry.department.org.name,

//       isEdited: false,
//     };
//   };

//   cancel = () => {
//     this.setState(this.getInitialStateFromProps(this.props.entry));
//   };

//   onFocus = () => {
//     this.setState({ isEdited: true });
//   };

//   handleChange = (e) => {
//     const { id, value, name } = e.target;

//     this.setState((prevState) => {
//       let newState = { ...prevState };

//       newState[name] = value;

//       if (name === "projectName" && value !== this.props.project.name) {
//         newState.projectId = null;
//       } else if (name === "projectName" && value === this.props.project.name) {
//         newState.projectId = this.props.project.id;
//       }

//       if (name === "taskName" && value !== this.props.task.name) {
//         newState.taskId = null;
//       } else if (name === "taskName" && value === this.props.task.name) {
//         newState.taskId = this.props.task.id;
//       }

//       if (id === "department") {
//         newState.departmentName =
//           this.props.orgs[this.state.orgId].departments[value].name;
//       }

//       if (id === "org") {
//         newState.orgName = this.props.orgs[value].name;
//       }

//       return newState;
//     });
//   };

//   setStartTime = (time) => {
//     let startTime = new Date(this.state.startTime.getTime());

//     if (typeof time === "string") {
//       const [hours, minutes] = time.split(":").map(Number);
//       startTime.setHours(hours);
//       startTime.setMinutes(minutes);
//       startTime.setSeconds(0);
//       startTime.setMilliseconds(0);
//     } else if (time instanceof Date) {
//       startTime.setFullYear(
//         time.getFullYear(),
//         time.getMonth(),
//         time.getDate(),
//       );
//     }

//     this.setState({
//       startTime,
//     });
//   };

//   setEndTime = (time) => {
//     let endTime = new Date(this.state.endTime.getTime());
//     if (typeof time === "string") {
//       const [hours, minutes] = time.split(":").map(Number);
//       endTime.setHours(hours);
//       endTime.setMinutes(minutes);
//       endTime.setSeconds(0);
//       endTime.setMilliseconds(0);
//     } else if (time instanceof Date) {
//       endTime.setFullYear(time.getFullYear(), time.getMonth(), time.getDate());
//     }

//     this.setState({
//       endTime,
//     });
//   };

//   render() {
//     let timeClassNamesa = "col-sm-2";
//     let timeClassNamesb = "col-sm-1";
//     if (this.props.entry.is_manual) {
//       timeClassNamesa = "col-sm-2 bg-danger-soft";
//       timeClassNamesb = "col-sm-1 bg-danger-soft";
//     }
//     if (this.props.entry.is_edited) {
//       timeClassNamesa = "col-sm-2 bg-warning-soft";
//       timeClassNamesb = "col-sm-1 bg-warning-soft";
//     }
//     return (
//       <Form
//         method="post"
//         className="row g-3 old-task"
//         onFocus={this.onFocus}
//         state={{ refresh: true }}
//       >
//         <input
//           type="text"
//           name="entryId"
//           value={this.props.entry.id}
//           hidden
//           readOnly
//         />
//         <div className="segment col-sm-2">
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Project"
//             aria-label="project"
//             name="projectName"
//             id="project"
//             required
//             value={this.state.projectName}
//             onChange={this.handleChange}
//           />
//           <input
//             type="text"
//             name="projectId"
//             value={this.props.entry.project.id}
//             hidden
//             readOnly
//           />
//         </div>
//         <div className="segment col-md-2">
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Task"
//             aria-label="task"
//             name="taskName"
//             id="task"
//             required
//             value={this.state.taskName}
//             onChange={this.handleChange}
//           />
//           <input
//             type="text"
//             name="taskId"
//             value={this.props.entry.task.id}
//             hidden
//             readOnly
//           />
//         </div>
//         <div className="segment col-sm">
//           <input
//             name="departmentName"
//             value={this.state.departmentName}
//             hidden
//             readOnly
//           />
//           <select
//             className="form-select"
//             aria-label="Department"
//             id="department"
//             name="departmentId"
//             onChange={this.handleChange}
//             value={this.state.departmentId}
//           >
//             {Object.values(this.props.orgs[this.state.orgId].departments).map(
//               (dept) => (
//                 <option value={dept.id} key={dept.id}>
//                   {dept.name}
//                 </option>
//               ),
//             )}
//           </select>
//         </div>
//         <div className="segment col-sm">
//           <input name="orgName" value={this.state.orgName} hidden readOnly />
//           <select
//             className="form-select"
//             aria-label="Org"
//             id="org"
//             name="orgId"
//             onChange={this.handleChange}
//             value={this.state.orgId}
//           >
//             {Object.keys(this.props.orgs).map((id) => (
//               <option value={id} key={id}>
//                 {this.props.orgs[id].name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className={"segment " + timeClassNamesa}>
//           {DateTime.fromISO(this.props.entry.start_time).toFormat(
//             "LLLL d, yyyy h:mm a",
//           )}
//         </div>
//         <div className={"segment " + timeClassNamesa}>
//           {DateTime.fromISO(this.props.entry.end_time).toFormat(
//             "LLLL d, yyyy h:mm a",
//           )}
//         </div>
//         <div className={"segment " + timeClassNamesb}>
//           {Duration.fromMillis(
//             DateTime.fromISO(this.props.entry.end_time)
//               .diff(DateTime.fromISO(this.props.entry.start_time))
//               .toMillis(),
//           ).toFormat("hh:mm:ss")}
//         </div>
//         <div className={!this.state.isEdited ? "hidden" : ""}>
//           <TransitionGroup>
//             <CSSTransition timeout={500} classNames="oldtask-edit-anim">
//               <EditForm
//                 onCancel={this.cancel}
//                 setStartTime={this.setStartTime}
//                 setEndTime={this.setEndTime}
//                 startTime={this.state.startTime}
//                 endTime={this.state.endTime}
//               />
//             </CSSTransition>
//           </TransitionGroup>
//         </div>
//       </Form>
//     );
//   }
// }

// export default withRouterFunctionality(withAuthentication(OldTask));
