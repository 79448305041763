import React, { useState } from 'react';

const DonutFlavorPicker = ({handleFlavor}) => {
  const [selectedOption, setSelectedOption] = useState('Time');
  
  const handleSelection = (flavor) => {
    setSelectedOption(flavor);
    return handleFlavor(flavor);
  }

  return (
      <>
      <div></div>
      <div className="btn-group mh-3" role="group" aria-label="Breakdown type picker">
        
        <button 
          type="button" 
          className={`btn mb-3 ${selectedOption === 'Time' ? 'btn-success' : 'btn-secondary'}`} 
          onClick={() => handleSelection('Time')}
        >
          Time breakdown
        </button>
        <button 
          type="button" 
          className={`btn mb-3 ${selectedOption === 'Cost' ? 'btn-success' : 'btn-secondary'}`} 
          onClick={() => handleSelection('Cost')}
        >
          Cost breakdown
        </button>
      </div>
      </>
  );
};

export default DonutFlavorPicker;