import { json, useLoaderData } from "react-router-dom";
import ServiceCard from "../../components/ServiceCard";
import { get } from "../../util/api";
import _ from "lodash";
import { useSelector } from "react-redux";
import NewServiceCard from "../NewServiceCard";
import { submitNewService, toSymbol } from "../../util/helpers";

const getStoredServices = async () => {
  try {
    const res = await get("/api/service_type/my");
    const storedServices = res.entities;
    return storedServices;
  } catch (error) {
    throw error;
  }
};

export async function profileServicesLoader() {
  try {
    const storedServices = await getStoredServices();
    return storedServices;
  } catch (error) {
    console.error(error);
  }
}

const formatPayload = (formData) => {
  const payload = {};
  for (const pair of formData.entries()) {
    if (
      pair[0] === "is_hourly" ||
      pair[0] === "hourly_rate" ||
      pair[0] === "new_word_rate" ||
      pair[0] === "unverified_word_rate" ||
      pair[0] === "account_id"
    ) {
      pair[1] = Number(pair[1]);
    }
    payload[pair[0]] = pair[1];
  }

  return payload;
};

export async function profileServicesAction({ request }) {
  try {
    let formData = await request.formData();
    const payload = formatPayload(formData);
    const response = await submitNewService(payload);
    return json(response, { status: 200 });
  } catch (error) {
    throw error;
  }
}

export default function ProfileServices() {
  const storedServices = useLoaderData();
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="card card-body">
      <h2 className="card-title mb-4 text-center">Service Plans</h2>

      {storedServices.map((service) => {
        return (
          <ServiceCard
            serviceObject={service}
            userId={user.id}
            key={service.id}
          />
        );
      })}

      <NewServiceCard symbol={toSymbol(user.currency)} userId={user.id} />
    </div>
  );
}
