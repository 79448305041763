export default function AutocompleteInput({
  inputType,
  value,
  name,
  suggestions,
  onInputChange,
  onSuggestionClick,
  onClick,
  submittedWithErrors,
}) {
  return (
    <div className={`col-sm-2 autocomplete-container`}>
      <input
        type="text"
        className={`form-control${value === "" && submittedWithErrors ? " is-invalid" : ""}`}
        placeholder={inputType.charAt(0).toUpperCase() + inputType.slice(1)}
        aria-label={inputType}
        id={inputType}
        value={value}
        name={name}
        onChange={onInputChange}
        onClick={onClick}
        required
        autoComplete="off"
      />
      <div className="suggestion-container">
        {suggestions &&
          suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="autocomplete-suggestion"
              onClick={() => onSuggestionClick(suggestion)}
            >
              <span
                className="autocomplete-suggestion-text"
              >
                {suggestion.name}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
}
