import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const withAuthentication = (WrappedComponent) => {
  class AuthComponent extends React.Component {
    render() {
      return <WrappedComponent user={this.props.user} {...this.props} />;
    }
  }

  return connect(mapStateToProps)(AuthComponent);
};

export default withAuthentication;

// import React from 'react';
// import { useSelector } from 'react-redux';

// const withAuthentication = (WrappedComponent) => {
//   const AuthComponent = (props) => {
//     const user = useSelector((state) => state.auth.user);
    
//     return <WrappedComponent user={user} {...props} />;
//   };

//   return AuthComponent;
// };

// export default withAuthentication;
