import React from "react";
import ReactDOM from "react-dom/client";
import "./scss/index.scss";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux-store";

import { useEffect } from "react";
import themeReducer from "./reducers/theme";
import App from "./App";
import { Navbar } from "./components";
import {
  Dashboard,
  Invite,
  Invoice,
  Login,
  Logout,
  Profile,
  Register,
  TeamReport,
  ThemeTest,
  Translation,
} from "./pages";
import { PrivateWrapper } from "./components/PrivateWrapper";

import { dashboardAction, dashboardLoader } from "./pages/Dashboard";
import { teamReportAction, teamReportLoader } from "./pages/TeamReport";
import ProfileDetails, {
  profileDetailsAction,
  profileDetailsLoader,
} from "./components/Profile/ProfileDetails";
import ProfileServices, {
  profileServicesAction,
  profileServicesLoader,
} from "./components/Profile/ProfileServices";
import ProfileChangePwd from "./components/Profile/ProfileChangePwd";
import ProfileTheme from "./components/Profile/ProfileTheme";
import ProfileTeam, {
  profileTeamLoader,
} from "./components/Profile/ProfileTeam";
import { invoiceAction, invoiceLoader } from "./pages/Invoice";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Navbar />}>
      <Route index element={<App />} />
      <Route element={<PrivateWrapper />}>
        {/* Protected routes */}
        <Route
          path="dashboard"
          loader={dashboardLoader}
          action={dashboardAction}
          element={<Dashboard />}
        />
        <Route path="invite" element={<Invite />} />
        <Route
          path="invoice"
          loader={invoiceLoader}
          action={invoiceAction}
          element={<Invoice key={"invoice"} showUser={false} />}
        />
        <Route path="profile" element={<Profile />}>
          <Route index element={<Navigate replace to="details" />} />
          <Route
            path="details"
            loader={profileDetailsLoader}
            action={profileDetailsAction}
            element={<ProfileDetails />}
          />
          <Route
            path="services"
            loader={profileServicesLoader}
            action={profileServicesAction}
            element={<ProfileServices />}
          />
          <Route path="changepwd" element={<ProfileChangePwd />} />
          <Route
            path="team"
            loader={profileTeamLoader}
            element={<ProfileTeam />}
          />
          <Route path="theme" element={<ProfileTheme />} />
        </Route>
        <Route path="logout" element={<Logout />} />
        <Route
          path="team-report"
          loader={teamReportLoader}
          action={teamReportAction}
          element={<TeamReport />}
        />
        <Route
          path="team-statistics"
          loader={invoiceLoader}
          action={invoiceAction}
          element={<Invoice key={"team-statistics"} showUser={true} />}
        />
        <Route path="translation" element={<Translation />} />
        <Route path="theme-test" element={<ThemeTest />} />
      </Route>
      {/* Public routes */}
      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />
    </Route>,
  ),
);

const ThemeProvider = ({ children }) => {
  useEffect(() => {
    try {
      let root = localStorage.getItem("persist:root");
      root = JSON.parse(root);
      let state = JSON.parse(root.theme);
      document.body.setAttribute("data-bs-theme", state.theme_name || "kronos");
    } catch (e) {
      document.body.setAttribute("data-bs-theme", "kronos");
    }
  }, []);

  return <>{children}</>;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <RouterProvider router={router} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
