import { useState, useEffect } from "react";
import { DateTime, Duration } from "luxon";

import { ArrowCollapse, ArrowExpand } from "../../assets";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { InvoiceTask, OldTask } from "..";

const computeTotalDuration = (entries) => {
  let totalDuration = Duration.fromMillis(0);
  let start, stop, duration;

  entries?.forEach((entry) => {
    start = DateTime.fromISO(entry.start_time);
    stop = DateTime.fromISO(entry.end_time);
    duration = stop.diff(start);
    totalDuration = totalDuration.plus(duration);
  });

  return totalDuration;
};

export default function OldTasksTable(props) {
  const [total, setTotal] = useState(Duration.fromObject({ milliseconds: 0 }));
  const [open, setOpen] = useState(!props.collapse);

  useEffect(() => {
    const totalDuration = computeTotalDuration(props.entries);
    setTotal(totalDuration);
  }, [props.entries]);

  const toggleOpen = () => setOpen(!open);

  return (
    <div className="row old-task-table mt-3">
      <button
        className="animated old-task table-total-header d-flex bg-secondary"
        onClick={toggleOpen}
      >
        {props.date ? (
          <span className="table-date" style={{ width: "10rem" }}>
            {DateTime.fromISO(props.date).toFormat("LLLL d, yyyy")}
          </span>
        ) : (
          <></>
        )}
        <p className="rjust">{total.toFormat("hh:mm:ss")}</p>
        <span>
          <img
            style={{ marginLeft: "10px", marginTop: "5px" }}
            src={open ? ArrowCollapse : ArrowExpand}
            alt="Toggle"
          />
        </span>
      </button>

      <div className="anim-window">
        {open && (
          <TransitionGroup>
            <div className="row g-3 old-task table-head bg-accent">
              {props.entryType === "team-task" && (
                <div className="col-md-2">Freelancer</div>
              )}
              <div
                className={
                  props.entryType === "team-task" ? "col-sm-1" : "col-sm-2"
                }
              >
                Project
              </div>
              <div className="col-md-2">Task</div>
              <div className="col-sm">Department</div>
              <div className="col-sm">Org</div>
              <div className="col-sm-2">Start</div>
              <div className="col-sm-2">Stop</div>
              <div
                className={
                  props.entryType === "team-task" ? "col-sm-1" : "col-sm"
                }
              >
                Duration
              </div>
            </div>
            {props.entries?.map((entry) => (
              <CSSTransition
                key={entry.id}
                timeout={500}
                classNames="table-entries"
              >
                <div className="table-entries row">
                  {props.entryType === "invoice-task" ||
                  props.entryType === "team-task" ? (
                    <InvoiceTask task={entry} />
                  ) : (
                    <OldTask entry={entry} />
                  )}
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        )}
      </div>
    </div>
  );
}
