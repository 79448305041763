const initialState = {
  theme_name: "kronos"
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'THEME':
      return {
        theme_name: action.payload
      };
    default:
      return state;
  }
};

export default themeReducer;
