import React from "react";
import { TeamTask } from "../components";
import Moment from 'react-moment';
import moment from 'moment';
import { ArrowCollapse, ArrowExpand } from "../assets";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

class TeamTasksTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: moment.duration("00:00:00"),
      open: true,
      tasks: []
    }
    //    this.toggleOpen = this.toggleOpen.bind(this)
  }

  componentDidUpdate(prevprops) {
    if (prevprops == this.props) return;
    if (!this.props.data) return;
    let total = moment.duration(0);

    let tasks = []
    for (let id in this.props.data) {
      this.props.data[id].tasks.forEach(task => {
        task.freelancer = this.props.data[id].freelancer;
        tasks.push(task)
      })
    }
    for (let i = 0; i < tasks.length; i++) {
      let task = tasks[i]
      let start = moment(task.start_time)
      let stop = moment(task.end_time)
      let duration = moment.duration(stop.diff(start))
      total.add(duration)
    }
    this.setState({ total: total, tasks: tasks })

  }

  toggleOpen() {
    this.setState({ open: !this.state.open })
  }

  render() {
    let entries = []
    if (this.state.open) {
      entries =
        <CSSTransition timeout={500} classNames="table-entries">
          <div className="table-entries row">
            <div className="row g-3 old-task table-head bg-accent">
              <div className="col-md-2">
                Freelancer
              </div>
              <div className="col-sm-1">
                Project
              </div>
              <div className="col-md-2">
                Task
              </div>
              <div className="col-sm">
                Department
              </div>
              <div className="col-sm">
                Org
              </div>
              <div className="col-sm-2">
                Start
              </div>
              <div className="col-sm-2">
                Stop
              </div>
              <div className="col-sm-1">
                Duration
              </div>
            </div>
            {
              this.state.tasks.map((task, index) => {
                return (
                  <TeamTask key={index} data={task} />
                )
              })
            }


          </div>
        </CSSTransition>
    }
    return (

      <div className="row old-task-table">
        <button className="animated old-task table-total-header d-flex bg-secondary" onClick={this.toggleOpen}>
          <Moment className="table-date" date={this.props.date} format="LL" />

          <p className="rjust">{this.state.total.hours().toString().padStart(2, "0")}:{this.state.total.minutes().toString().padStart(2, "0")}:{this.state.total.seconds().toString().padStart(2, "0")}</p>
          <span><img style={{ marginLeft: "10px", marginTop: "5px" }} src={this.state.open ? ArrowCollapse : ArrowExpand} /></span>

        </button>

        <div className="anim-window">
          <TransitionGroup>
            {entries}
          </TransitionGroup>
        </div>
      </div>
    )
  }
}

export default TeamTasksTable;