import { useDispatch, useSelector } from "react-redux";
import { storeTheme } from "../../actions/theme";

export default function ProfileTheme() {
  const selectedTheme = useSelector((state) => state.theme.theme_name);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value } = e.target;
    dispatch(storeTheme(value));
    document.body.setAttribute("data-bs-theme", value);
  };

  return (
    <div className="card card-body">
      <h2 className="card-title mb-4 text-center">Theme</h2>
      <div className="row">
        <div className="col-md">
          <div className="form-floating">
            <select
              className="form-select"
              id="theme"
              aria-label=""
              value={selectedTheme}
              onChange={handleChange}
            >
              <option value="kronos">Kronos</option>
              <option value="dark">Dark</option>
            </select>
            <label htmlFor="theme">Theme</label>
          </div>
        </div>
      </div>
    </div>
  );
}
