import { DateTime, Duration } from "luxon";

export default function InvoiceTask({ task }) {
  let timeClassNamesa = "col-sm-2";
  let timeClassNamesb = "col-sm-1";

  if (task.is_manual) {
    timeClassNamesa = "col-sm-2 bg-danger-soft";
    timeClassNamesb = "col-sm-1 bg-danger-soft";
  }

  if (task.is_edited) {
    timeClassNamesa = "col-sm-2 bg-warning-soft";
    timeClassNamesb = "col-sm-1 bg-warning-soft";
  }

  return (
    <div className="row invoice-task">
      {task.freelancer && (
        <div className="col-md-2">
          <p>{task.freelancer.name} {task.freelancer.surname}</p>
        </div>
      )}
      <div className={task.freelancer ? "col-sm-1" : "col-sm-2"}>
        <p>{task.project?.name}</p>
      </div>
      <div className="col-md-2">
        <p>{task.task?.name}</p>
      </div>
      <div className="col-sm">
        <p>{task.department.name}</p>
      </div>
      <div className="col-sm">
        <p>{task.department.org.name}</p>
      </div>
      <div className={timeClassNamesa}>
        {DateTime.fromISO(task.start_time).toFormat("LLLL d, yyyy h:mm a")}
      </div>
      <div className={timeClassNamesa}>
        {DateTime.fromISO(task.end_time).toFormat("LLLL d, yyyy h:mm a")}
      </div>
      <div className={timeClassNamesb}>
        {Duration.fromMillis(
          DateTime.fromISO(task.end_time)
            .diff(DateTime.fromISO(task.start_time))
            .toMillis(),
        ).toFormat("hh:mm:ss")}
      </div>
    </div>
  );
}
