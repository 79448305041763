import { post } from "../util/api";
import { DateTime } from "luxon";

import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const initialUserState = {
    email: "",
    password: "",
    passwordRepeat: "",
    name: "",
    surname: "",
    currency: 0,
    availability: 0,
  };

  const navigate = useNavigate();

  const [user, setUser] = useState(initialUserState);
  const [inviteCode, setInviteCode] = useState("");
  const [inviteAccepted, setInviteAccepted] = useState(false);

  const checkInviteCodeDebounced = debounce(async (code) => {
    try {
      const res = await post("/auth/register/check-invite", {
        inviteCode: code,
      });

      if (!res) return;

      setInviteAccepted(true);
      setInviteCode(res.code);
      setUser({
        ...user,
        email: res.receiver_email,
      });
    } catch (error) {
      console.error(error);
    }
  }, 300);

  useEffect(() => {
    return () => {
      checkInviteCodeDebounced.cancel();
    };
  }, []);

  const handleInviteCode = (e) => {
    const value = e.target.value;
    setInviteCode(value);

    if (value.length > 5) {
      if (!inviteAccepted) {
        checkInviteCodeDebounced(value);
      } else {
        checkInviteCodeDebounced.cancel();
      }
    }
  };

  const register = async (e) => {
    e.preventDefault();

    if (user.password !== user.passwordRepeat) {
      alert("Passwords do not match");
      return;
    }

    const { email, password, name, surname, currency, availability } = user;
    let data = {
      name,
      surname,
      email,
      password,
      inviteCode,
      currency,
      availability,
    };

    try {
      await post("/auth/register", data);
      navigate("login");
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="buffer-5vh" />
          <form onSubmit={register} className="card card-body">
            <h2 className="card-title mb-4 text-center">
              {inviteAccepted ? "Profile Details" : "Invitation"}
            </h2>
            <div className="row g-2">
              {!inviteAccepted && (
                <div className="col-md">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="inviteCode"
                      value={inviteCode}
                      onChange={handleInviteCode}
                    />
                    <label htmlFor="inviteCode">Your Invite Code</label>
                  </div>
                </div>
              )}
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={user.email}
                    disabled
                    readOnly
                  />
                  <label htmlFor="email">Email address</label>
                </div>
              </div>

              {inviteAccepted && (
                <div className="col-md">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGrid"
                      value={DateTime.now().toFormat("LLLL d, yyyy h:mm a")}
                      readOnly
                      disabled
                    />
                    <label htmlFor="floatingInputGrid">Registered on</label>
                  </div>
                </div>
              )}
            </div>

            {inviteAccepted && (
              <>
                <div className="row">
                  <div className="form-floating">
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={user.password}
                      onChange={handleChange}
                    />
                    <label htmlFor="password">Password</label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating">
                    <input
                      type="password"
                      className="form-control"
                      id="passwordRepeat"
                      value={user.passwordRepeat}
                      onChange={handleChange}
                    />
                    <label htmlFor="passwordRepeat">Password (repeat)</label>
                  </div>
                </div>
                <div className="row g-2">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={user.name}
                        onChange={handleChange}
                      />
                      <label htmlFor="name">Name</label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="surname"
                        value={user.surname}
                        onChange={handleChange}
                      />
                      <label htmlFor="surname">Surname</label>
                    </div>
                  </div>
                </div>

                <div className="row g-2">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="number"
                        className="form-control"
                        id="availability"
                        value={user.availability}
                        onChange={handleChange}
                      />
                      <label htmlFor="availability">
                        Availability (Hours/week)
                      </label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="currency"
                        aria-label=""
                        value={user.currency}
                        onChange={handleChange}
                      >
                        <option value="0">USD ($)</option>
                        <option value="1">EUR (€)</option>
                        <option value="2">GPB (£)</option>
                      </select>
                      <label htmlFor="currency">Currency</label>
                    </div>
                  </div>
                </div>
                <div className="buffer-5vh" />
                <button type="submit" className="btn btn-primary">
                  Register
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
