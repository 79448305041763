import React from "react";
import { useRef, useEffect } from "react";
// import { OffCanvasElement } from "../components";
// import { CalendarSVG, ClockSVG, ReportSVG } from "../assets";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const DrawerLink = ({ to, className, onClick, children }) => {
  return (
    <Link to={to} className={className} onClick={onClick}>
      {children}
    </Link>
  );
};

export default function LeftDrawer() {
  const drawerRef = useRef();
  const user = useSelector((state) => state.auth.user)

  // Drawer closes when user clicks outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        hide();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Hides drawer
  const hide = () => {
    document.getElementById("left-drawer").classList.remove("show");
  };

  return (
    <div
      className="offcanvas offcanvas-start"
      tabIndex="-1"
      id="left-drawer"
      aria-labelledby="left-drawer"
      ref={drawerRef}
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title nav-brand" id="offcanvasExampleLabel">
          TRACKRONOS
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={hide}
        ></button>
      </div>

      <div className="sidebar-sticky offcanvas-body">
        <div className="nav flex-column">
          {/* My Account Category */}
          <li className="nav-item">
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center text-muted mb-10 mt-4 px-3">
              My Account
            </h6>
            <ul className="nav flex-column align-items-start mb-2">
              <li className="nav-item">
                <DrawerLink
                  to="dashboard"
                  className="nav-link btn btn-outline-primary text-b-nav"
                  onClick={hide}
                >
                  Dashboard
                </DrawerLink>
              </li>
              <li className="nav-item">
                <DrawerLink
                  to="profile"
                  className="nav-link btn btn-outline-primary text-b-nav"
                  onClick={hide}
                >
                  Settings
                </DrawerLink>
              </li>
              <li className="nav-item">
                <DrawerLink
                  to="invoice"
                  className="nav-link btn btn-outline-primary text-b-nav"
                  onClick={hide}
                >
                  Invoice
                </DrawerLink>
              </li>
            </ul>
          </li>

          {/* Team Category */}
          <li className="nav-item">
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center text-muted mb-1 mt-4 px-3">
              Team
            </h6>
            <ul className="nav flex-column align-items-start mb-2">
              {user?.role !== 0 && (
                <>
                  <li className="nav-item">
                    <DrawerLink
                      to="team-statistics"
                      className="nav-link btn btn-outline-primary text-b-nav"
                      onClick={hide}
                    >
                      Team Member Statistics
                    </DrawerLink>
                  </li>
                  <li className="nav-item">
                    <DrawerLink
                      to="team-report"
                      className="nav-link btn btn-outline-primary text-b-nav"
                      onClick={hide}
                    >
                      Team Report
                    </DrawerLink>
                  </li>
                </>
              )}
              <li className="nav-item">
                <DrawerLink
                  to="invite"
                  className="nav-link btn btn-outline-primary text-b-nav"
                  onClick={hide}
                >
                  Invite Someone
                </DrawerLink>
              </li>
            </ul>
          </li>

          <li className="nav-item">
          <h6 className="sidebar-heading d-flex justify-content-between align-items-center text-muted mb-1 mt-4 px-3">
              Support
            </h6>
            <ul className="nav flex-column align-items-start mb-2">
              <li className="nav-item">
                <DrawerLink
                  to="https://form.asana.com/?k=qwxi739h7RSLfJQ7MoHF9w&d=453442486506794"
                  className="nav-link btn btn-outline-primary text-b-nav"
                  onClick={hide}
                >
                  Report a problem
                </DrawerLink>
              </li>
            </ul>
          </li>
        </div>
      </div>
    </div>
  );
}
