import React from 'react';
import { withAuthentication } from '../wrappers';
import moment from 'moment';

class MiniTotal extends React.Component {
  constructor(props) {
    super(props);
    

    let plans = {}
    if(this.props.tasks)
      this.props.tasks.forEach(task=>{
        if(task.project){
          if(!plans[task.project.name]){
            plans[task.project.name] = []
          }
          plans[task.project.name].push(task)
        
        }
      })
        

    this.state={
      currency: this.props.currency,
      total: moment.duration(0),
      total_float:0,
      total_str: "00:00:00",
      cost: 0,
      symbol:this.currencyToSymbol(this.props.user.currency),
      plans:plans,
      tasks: this.props.tasks,
      tasks_by_plan:{}
    }

  }



  currencyToSymbol(currency) {
    if (currency == 0) return "$"
    if (currency == 1) return "€"
    if (currency == 2) return "£"
  }

  componentDidUpdate(prevprops, prevstate){
    if(this.props.tasks == prevprops.tasks )  return;
    let plans = {}
    let plan_details = {}
    if(!this.props.tasks.length) return;
    try{
      this.props.tasks.forEach(task=>{
        if(!plans[task.service.name]){
          plans[task.service.name] = []
        }
        plans[task.service.name].push(task)
  
        if(!plan_details[task.service.name]){
          plan_details[task.service.name] = task.service
        }
  
        
      })
      this.setState({
        symbol: this.currencyToSymbol(this.props.user.currency),
        tasks_by_plan: plans,
        plans: plan_details
      })
    }catch(e){
      this.setState({
        hidden:true
      })
    }

    this.setState({
      symbol: this.currencyToSymbol(this.props.user.currency),
      tasks_by_plan: plans,
      plans: plan_details
    })
  }

  comparisonToAvailability(total, availability, monthly=true){
    if(monthly) availability = 4*availability;
    if(total>availability) return <span style={{color:"#00aa00"}}>⇑+{((total - availability)/availability*100).toFixed(2)}% </span>
    return <span style={{color:"#aa0000"}}>⇓{((total - availability)/availability).toFixed(2)*100}%</span>
  }

  render() {
    let grand_total = moment.duration(0);
    let total_cost = 0;
    Object.keys(this.state.tasks_by_plan).forEach(plan=>{
      let total = moment.duration(0);
      let tasks = this.state.tasks_by_plan[plan]
      for (let i = 0; i < tasks.length; i++) {
        let task = tasks[i]
        let start = moment(task.start_time)
        let stop = moment(task.end_time)
        let duration = moment.duration(stop.diff(start))
        total.add(duration)
      }
      grand_total.add(total)
      total = total.asHours()
      total_cost += total*this.state.plans[plan].hourly_rate
    })
    return this.currencyToSymbol(this.props.user.currency) + total_cost.toFixed(2)
  }
}

export default withAuthentication(MiniTotal);