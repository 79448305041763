// index.js
const developmentConfig = require('./development');
const productionConfig = require('./production');
const stagingConfig = require('./staging');

const env = process.env.NODE_ENV || 'development';

let config;

if (env === 'production') {
  config = productionConfig;
} else if (env === 'staging') {
  config = stagingConfig;
} else {
  config = developmentConfig;
}

export default config;