import OldTasksTable from "./OldTasksTable";
import { DateTime } from "luxon";
import { useLoaderData } from "react-router-dom";

export default function TasksByDate() {
  const { entriesByDate } = useLoaderData();

  return (
    <div className="mt-5">
      {entriesByDate.length > 0 ? (
        entriesByDate.map((entryGroup) => (
          <OldTasksTable
            key={entryGroup.date}
            entries={entryGroup.entries}
            date={entryGroup.date}
            entryType="old-task"
            //only collapse if date is older than a week
            collapse={
              entryGroup.date < DateTime.now().minus({ days: 7 }).toISODate()
            }
          />
        ))
      ) : (
        <div>You have not added any entries yet.</div>
      )}
    </div>
  );
}
