import { combineReducers } from "redux";
import authReducer from "./reducers/auth";
import taskReducer from "./reducers/task";
import themeReducer from "./reducers/theme";

const rootReducer = combineReducers({
  auth: authReducer,
  task: taskReducer,
  theme: themeReducer,
  // Other reducers...
});

export default rootReducer;
