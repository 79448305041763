import { Form, useLoaderData } from "react-router-dom";

const frameOptions = [
  {
    label: "This Month",
    id: 0,
  },
  {
    label: "Last Month",
    id: 1,
  },
  {
    label: "This Week (Monday to Sunday)",
    id: 2,
  },
  {
    label: "Last Week (Monday to Sunday)",
    id: 3,
  },
  {
    label: "This Year",
    id: 4,
  },
  {
    label: "This Week (Friday to Thursday)",
    id: 5,
  },
  {
    label: "Custom Selection",
    id: 6
  }
];

export default function InvoiceSelect({
  showUser,
  handleSelect,
  handleTeamMemberSelect,
  handleServiceSelect
}) {
  const { team, selectedFrame, selectedFreelancer, selectedService, services } = useLoaderData();
  return (
    <Form method="post" action="/invoice" className="card card-body mb-5">
      {showUser && (
        <select
          className="form-select mb-2"
          onChange={handleTeamMemberSelect}
          value={selectedFreelancer}
        >
          {team.map((freelancer) => (
            <>
              <option key={freelancer.id} value={freelancer.id}>
                {freelancer.name} {freelancer.surname}
              </option>
            </>
          ))}
        </select>
      )}
      <select
        className="form-select"
        aria-label="Default select example"
        onChange={handleSelect}
        value={selectedFrame}
      >
        {frameOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
      {handleServiceSelect && (
        <select
        className="form-select"
        aria-label="Default select example"
        onChange={handleServiceSelect}
        value={selectedService}
      >
        <option key={-1} value={-1}>
            All
          </option>
        {services.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
        
      </select>
      )}
    </Form>
  );
}
