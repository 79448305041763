export default function SelectInput({
  ariaLabel,
  onChange,
  value,
  options,
  name
}) {
  return (
    <div className="col-sm">
      <select
        className="form-select"
        aria-label={ariaLabel}
        onChange={onChange}
        value={value}
        name={name}
        required
      >
        {options.length > 0 ? (
          options.map((option) => (
            <option key={option.id} value={option.id} name={option.name}>
              {option.name}
            </option>
          ))
        ) : (
          <option value="">Select {ariaLabel}</option>
        )}
      </select>
    </div>
  );
}
