import React from "react";
import { connect } from "react-redux";
import { login, logout } from "../actions/auth";
import {
  storeDepartmentId,
  storeOrgId,
  storeProjectId,
  storeProjectName,
  storeServiceId,
  storeTaskId,
  storeTaskName,
} from "../actions/task";

const withActions = (WrappedComponent) => {
  const mapDispatchToProps = (dispatch) => {
    return {
      login: (userData) => dispatch(login(userData)),
      logout: () => dispatch(logout()),
      storeTaskName: (taskName) => dispatch(storeTaskName(taskName)),
      storeTaskId: (taskId) => dispatch(storeTaskId(taskId)),
      storeProjectName: (projectName) =>
        dispatch(storeProjectName(projectName)),
      storeProjectId: (projectId) => dispatch(storeProjectId(projectId)),
      storeDepartmentId: (departmentId) =>
        dispatch(storeDepartmentId(departmentId)),
      storeOrgId: (orgId) => dispatch(storeOrgId(orgId)),
      storeServiceId: (serviceId) => dispatch(storeServiceId(serviceId)),
      // Add other action creators as needed
    };
  };

  class ActionComponent extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(null, mapDispatchToProps)(ActionComponent);
};

export default withActions;
