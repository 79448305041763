import React from "react";
import { post } from "../util/api";
import { withAuthentication, withActions } from "../wrappers";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      invitee: "",
      role: 0,
    };
  }

  roleTable = {
    0: "Freelancer",
    1: "Manager",
    2: "Admin",
    3: "Developer",
  };

  handleChange = (e) => {
    this.setState({
      invitee: e.target.value,
    });
  };

  generateInvite = async (e) => {
    e.preventDefault();

    if (!this.state.invitee) {
      alert("Please, fill out all fields before submitting.");
    }

    const res = await post("/api/user/invite", {
      email: this.state.invitee,
      role: this.state.role,
    });

    if (res.id) {
      this.setState({
        code: res.code,
      });
    }
  };

  setRole = (e) => {
    this.setState({
      role: parseInt(e.target.value),
    });
  };

  refresh = () => {
    this.setState({
      code: null,
      invitee: "",
      role: 0,
    });
  };

  render() {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <form onSubmit={this.generateInvite} className="col-md-8">
            <div className="card card-body">
              <h2 className="card-title mb-4 text-center">Invite Someone</h2>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="invitee"
                    required
                    value={this.state.invitee}
                    onChange={this.handleChange}
                    disabled={this.state.code}
                  />
                  <label htmlFor="invitee">Email address</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <select
                    id="role"
                    className="form-select"
                    aria-label="Role"
                    onChange={this.setRole}
                    value={this.state.role}
                    required
                    disabled={this.state.code}
                  >
                    {Object.keys(this.roleTable).map((role) => {
                      if (this.props.user.role >= role) {
                        return (
                          <option key={role} value={role}>
                            {this.roleTable[role]}
                          </option>
                        );
                      }
                    })}
                  </select>
                  <label htmlFor="role">Role</label>
                </div>
              </div>
              <div className="buffer-5vh" />
              <button
                className="btn btn-primary"
                type="submit"
                disabled={this.state.code}
              >
                Invite
              </button>
            </div>

            <div className="buffer-5vh" />
            {this.state.code && (
              <div className="card card-body">
                <h2 className="card-title mb-4 text-center">
                  Invite Code for {this.state.invitee}
                </h2>
                <div className="col-md">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="code"
                      value={this.state.code}
                      disabled
                      style={{ fontSize: "30px", textAlign: "center" }}
                    />
                  </div>
                  <p>
                    Invitee should navigate to the registration page and use
                    this invite code.
                  </p>
                </div>
              </div>
            )}
          </form>
          {this.state.code && (
            <button
              style={{ width: "auto", fontWeight: "bold" }}
              onClick={this.refresh}
            >
              Create another invite
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default withActions(withAuthentication(Profile));
