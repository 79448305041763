import React from "react";
import { Hamburger } from "../../assets";
import { Link, Outlet } from "react-router-dom";
import { LeftDrawer } from "..";
import ProfileDropdown from "./ProfileDropdown";
import { useSelector } from "react-redux";

export default function Navbar() {
  const user = useSelector((state) => state.auth.user)

  const showDrawer = () => {
    let drawer = document.getElementById("left-drawer");
    drawer.classList.toggle("show");
  };

  return (
    <>
      <LeftDrawer />
      <nav className="navbar navbar-expand-lg text-color no-print">
        <div className="container-fluid">
          <button className="btn" onClick={showDrawer}>
            <img
              src={Hamburger}
              style={{ height: "30px", marginRight: "15px" }}
              alt=""
            />
          </button>

          <Link className="navbar-brand" to="dashboard">
            TRACKRONOS
          </Link>
          {/* 
          <div
            className="navbar-collapse collapse"
            id="navbarSupportedContent"
          ></div>

          <div className="navbar-nav mb-lg-0 mb-2 me-auto"></div> */}

          {!user ? (
            <Link className="btn btn-success" to="login">
              Login
            </Link>
          ) : (
            <ProfileDropdown />
          )}
        </div>
      </nav>
      <Outlet />
    </>
  );
}
