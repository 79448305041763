import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../util/helpers";

export default function Login() {
  const [userDetails, setUserDetails] = useState({
    username: "",
    password: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await userLogin(userDetails.username, userDetails.password);

      if (res && res.user) {
        dispatch(login(res.user));
        if(res.user.role == 0)
          navigate("/dashboard", { replace: true });
        else 
          navigate("/team-report", { replace: true });
      }
    } catch (error) {
      console.error(error);
      alert(`Login has failed. Please, try again.`);
    }
  };

  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">
            Username
          </label>
          <input
            type="text"
            className="form-control"
            id="username"
            name="username"
            value={userDetails.username}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            value={userDetails.password}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Login
        </button>
      </form>
    </div>
  );
}
