import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { DateTime } from "luxon";
import { roundTo } from "../../util/helpers";
import { useLoaderData } from "react-router-dom";

const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Workhours",
    },
  },
};

export default function HourHistogram() {
  const { entries } = useLoaderData();
  const [data, setData] = useState(null);

  const update = () => {
    const startDate = DateTime.fromISO(entries[entries.length - 1].start_time);
    const endDate = DateTime.fromISO(entries[0].start_time);
    const labels = generateLabels(startDate, endDate);
    const counters = initializeCounters(labels);

    accumulateHours(entries, counters);

    const data = prepareChartData(labels, counters);
    setData(data);
  };

  const generateLabels = (startDate, endDate) => {
    const labels = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      labels.push(currentDate.toFormat("dd/LL/yy"));
      currentDate = currentDate.plus({ days: 1 });
    }

    return labels;
  };

  const initializeCounters = (labels) => {
    const counters = { clokedHours: {}, editedEntries: {}, manualEntries: {} };
    labels.forEach((label) => {
      counters.clokedHours[label] = 0;
      counters.editedEntries[label] = 0;
      counters.manualEntries[label] = 0;
    });
    return counters;
  };

  const accumulateHours = (entries, counters) => {
    entries.forEach((entry) => {
      const start = DateTime.fromISO(entry.start_time);
      const stop = DateTime.fromISO(entry.end_time);
      const duration = stop.diff(start).as("hours");
      const index = start.toFormat("dd/LL/yy");

      if (entry.is_edited) {
        counters.editedEntries[index] += roundTo(duration, 2);
      } else if (entry.is_manual) {
        counters.manualEntries[index] += roundTo(duration, 2);
      } else {
        counters.clokedHours[index] += roundTo(duration, 2);
      }
    });
  };

  const prepareChartData = (labels, counters) => ({
    labels,
    datasets: [
      {
        label: "Clocked hours",
        data: Object.values(counters.clokedHours),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Manual entries",
        data: Object.values(counters.manualEntries),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(253, 155, 53, 0.5)",
      },
      {
        label: "Edited entries",
        data: Object.values(counters.editedEntries),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(253, 53, 53, 0.5)",
      },
    ],
  });

  useEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      BarElement,
      Title,
      Tooltip,
      Filler,
      Legend,
    );
    update();
  }, [entries]);

  return data && <Bar options={options} data={data} />;
}
