import { Link } from "react-router-dom";

export default function ServiceWarningPopup() {
  return (
    <div
      className="alert alert-warning alert-dismissible fade show"
      role="alert"
    >
      <strong>Warning!</strong> You have not set up any service types. Please
      set up a time-driven service type from the{" "}
      <Link to="/profile/services">services tab in the profile page</Link> to
      start tracking your time.
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  );
}
