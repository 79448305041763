import React from "react";
import { get } from "../util/api";
import { DateTime, Duration } from "luxon";
import {
  OldTasksTable,
  HourHistogram,
  TimeDonut,
  TotalInvoice,
  TimeFrameControls,
} from "../components";
import Moment from "react-moment";
import moment from "moment";
import { withAuthentication } from "../wrappers";
import "../assets/print.css";
import {
  getTeamInfo,
  loadRecentTasks,
  removePrintParams,
  setPrintParams,
} from "../util/helpers";

class TeamMemberInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      orgs: {},
      now: DateTime.now(),
      tableName: "This Month",
      total: moment.duration(0),
      totalFloat: 0.0,
      selected_freelancer: {},
      team: [],
    };

    this.date = new Date();
    this.getTeam = getTeamInfo.bind(this);
    this.getRecentTasks = loadRecentTasks.bind(this);
    this.setPrintParams = setPrintParams.bind(this);
    this.removePrintParams = removePrintParams.bind(this);
  }

  componentDidUpdate(prevprops, prevstate) {
    if (this.state.tasks != prevstate.tasks) {
      this.setState({
        total: this.getTotalSelected(this.state.tasks),
      });
    }
  }

  loadOrgs() {
    get("/api/org/search?join=departments").then((res) => {
      let orgs = {};
      res.entities.forEach((org) => {
        let departments = {};
        org.departments.forEach((department) => {
          departments[department.id] = department;
        });
        org.departments = departments;
        orgs[org.id] = org;
      });
      this.setState({
        orgs: orgs,
      });
    });
  }

  componentDidMount() {
    this.getTeam();
    this.loadOrgs();
    this.setPrintParams();
  }

  getTotalSelected(tasks) {
    let total = Duration.fromMillis(0);
    let start, stop, duration;
    for (const task of tasks) {
      start = DateTime.fromISO(task.start_time);
      stop = DateTime.fromISO(task.end_time);
      duration = stop.diff(start);
      total = total.plus(duration);
    }

    return total;
  }

  componentWillUnmount() {
    this.removePrintParams();
  }

  render() {
    return (
      <div className="container">
        <div className="buffer-5vh" />
        <button className="btn btn-success" onClick={() => window.print()}>
          Save as PDF
        </button>
        <TimeFrameControls
          showUser={true}
          team={this.state.team}
          callback={(r, f) => {
            if (f) {
              this.setState(
                {
                  selected_freelancer: f,
                  limitQuery: r,
                },
                () => {
                  this.getRecentTasks(r);
                },
              );
            } else
              this.setState(
                {
                  limitQuery: r,
                },
                () => {
                  this.getRecentTasks(r);
                },
              );
          }}
        />
        <div className="row">
          <div className="card card-body">
            <div className="row">
              <div className="col">
                <div className="card card-body" style={{ height: "370px" }}>
                  <HourHistogram
                    tasks={this.state.tasks}
                    month={moment(this.state.date)}
                  />
                </div>
              </div>
              <div className="col">
                <div className="card card-body">
                  <TimeDonut
                    tasks={this.state.tasks}
                    month={moment(this.state.date)}
                    keyname="project"
                    height="300px"
                  />
                </div>
              </div>
              <div className="col">
                <div className="card card-body">
                  <TimeDonut
                    tasks={this.state.tasks}
                    month={moment(this.state.date)}
                    keyname="task"
                    height="300px"
                  />
                </div>
              </div>
              <div className="col">
                <div className="card card-body">
                  <TotalInvoice tasks={this.state.tasks} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buffer-5vh" />
        <div className="row">
          <div className="card card-body">
            <OldTasksTable
              tasks={this.state.tasks}
              date={this.state.now}
              entryType="invoice-task"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withAuthentication(TeamMemberInvoice);
