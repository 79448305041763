import React from "react";
import { DateTime, Duration } from "luxon";

class TeamTask extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let timeClassNamesa = "col-sm-2";
    let timeClassNamesb = "col-sm-1";
    if (this.props.data.is_manual) {
      timeClassNamesa = "col-sm-2 bg-danger-soft";
      timeClassNamesb = "col-sm-1 bg-danger-soft";
    }
    if (this.props.data.is_edited) {
      timeClassNamesa = "col-sm-2 bg-warning-soft";
      timeClassNamesb = "col-sm-1 bg-warning-soft";
    }
    return (
      <div className="row invoice-task">
        <div className="col-md-2">
          <p>{this.props.data.freelancer.name}</p>
        </div>
        <div className="col-sm-1">
          <p>{this.props.data.project?.name}</p>
        </div>
        <div className="col-md-2">
          <p>{this.props.data.task?.name}</p>
        </div>
        <div className="col-sm">
          <p>{this.props.data.department.name}</p>
        </div>
        <div className="col-sm">
          <p>{this.props.data.department.org.name}</p>
        </div>
        <div className={timeClassNamesa}>
          {DateTime.fromISO(this.props.data.start_time).toFormat(
            "LLLL d, yyyy h:mm a",
          )}
        </div>
        <div className={timeClassNamesa}>
          {DateTime.fromISO(this.props.data.end_time).toFormat(
            "LLLL d, yyyy h:mm a",
          )}
        </div>
        <div className={timeClassNamesb}>
          {Duration.fromMillis(
            DateTime.fromISO(this.props.data.end_time)
              .diff(DateTime.fromISO(this.props.data.start_time))
              .toMillis(),
          ).toFormat("hh:mm:ss")}
        </div>
      </div>
    );
  }
}

export default TeamTask;
