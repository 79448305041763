import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../util/helpers";

export default function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(null);

  useEffect(() => {
    userLogout()
      .then(() => {
        dispatch(logout());
        navigate("/", { replace: true });
      })
      .catch((error) => {
        console.error(error);
        setError("Logout failed. Please, try again.");
      });
  }, [dispatch, navigate]);

  if (error) {
    return <p>{error}</p>;
  }

  return <p>Logging out...</p>;
}
