import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ProfilePlaceholder } from "../../assets";

export default function ProfileDropdown() {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="justify-content-end flex" ref={dropdownRef}>
      {/* <Link className="d-flex nav-link nav-username" to="profile">
        {user.name} {user.surname}
      </Link> */}

      {/* Toggle dropdown */}
      <div className="d-flex nav-link" onClick={toggleDropdown}>
        <img
          className="thumbnail"
          src={ProfilePlaceholder}
          alt="Profile"
          style={{ cursor: "pointer" }}
        ></img>

        {/* Dropdown Menu */}
        {dropdownVisible && (
          <div className="dropdown-menu">
            <Link className="dropdown-item" to="profile">
              Profile
            </Link>
            <Link className="dropdown-item" to="profile/theme">
              Theme
            </Link>
            <Link className="dropdown-item" to="/logout">
              Log Out
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
