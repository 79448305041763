import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { DateTime } from "luxon";
import { roundTo } from "../util/helpers";

const topNBuckets = 9999;

export default function TotalHoursTable({ keyname }) {
  const { entries, availabilityMultiplier } = useLoaderData();

  const [topLabels, setTopLabels] = useState(null);
  const [topDurations, setTopDurations] = useState(null);
  const [totalDuration, setTotalDuration] = useState(0);

  const update = () => {
    let categoryName = keyname || "project";

    const entryDurations = {};
    let totalDuration = 0;

    entries.forEach((entry) => {
      const uniqueKey = getUniqueKey(entry, categoryName);
      const duration = calculateDuration(entry);
      entryDurations[uniqueKey] = roundTo(
        (entryDurations[uniqueKey] || 0) + duration,
        2,
      );
      totalDuration += duration;
    });

    const { topLabels, topDurations, otherDuration } = selectTopDurations(
      entryDurations,
      topNBuckets,
    );
    if (otherDuration > 0) {
      topLabels.push("other");
      topDurations.push(otherDuration);
    }

    setTopLabels(topLabels);
    setTopDurations(topDurations);
    setTotalDuration(roundTo(totalDuration, 2));
  };

  const calculateDuration = (entry) => {
    const start = DateTime.fromISO(entry.start_time);
    const end = DateTime.fromISO(entry.end_time);
    const duration = end.diff(start, "hours").hours;
    return duration;
  };

  const selectTopDurations = (durations, maxCount) => {
    const entries = Object.entries(durations).sort((a, b) => b[1] - a[1]);
    const topEntries = entries.slice(0, maxCount);
    const otherEntries = entries.slice(maxCount);
    const otherDuration = otherEntries.reduce(
      (sum, [, duration]) => sum + duration,
      0,
    );

    return {
      topLabels: topEntries.map(([key]) => key),
      topDurations: topEntries.map(([, duration]) => duration),
      otherDuration,
    };
  };

  const getUniqueKey = (entry, categoryName) => {
    let uniqueKey = entry[categoryName]?.name;
    if(keyname == "freelancer"){
      uniqueKey = entry.freelancer.name + " " + entry.freelancer.surname;
    }else{
      const categoryToArray = categoryName.split(".");
      if (categoryToArray.length !== 1) {
        const oneLevelDown = categoryToArray[0];
        const twoLevelsDown = categoryToArray[1];
        uniqueKey = entry[oneLevelDown][twoLevelsDown]["name"];
      }
    }

    return uniqueKey;
  };

  useEffect(() => {
    update();
  }, [entries]);

  return (
    topLabels &&
    topDurations && (
      <table>
        <thead>
          <tr>
            <th>{keyname}</th>
            <th>Hours</th>
            <th>% of team hours</th>
            <th>% of availability</th>
          </tr>
        </thead>
        <tbody>
          {topLabels.map((label, index) => {
            const percent =
              topDurations[index] /
              topDurations.reduce((sum, value) => sum + value, 0);

            const availability = entries.find(
              (entry) => getUniqueKey(entry, keyname) === label,
            ).freelancer.availability;

            return (
              <tr key={label}>
                <td>{label}</td>
                <td>{topDurations[index]}</td>
                <td>{(percent * 100).toFixed(2)}%</td>
                <td>
                  {topDurations[index] > availability * availabilityMultiplier ? (
                    <span style={{ color: "#00aa00" }}>
                      ⇑+
                      {(
                        ((topDurations[index] - availability * availabilityMultiplier) /
                          availability) *
                        availabilityMultiplier *
                        100
                      ).toFixed(2)}
                      %
                    </span>
                  ) : (
                    <span style={{ color: "#aa0000" }}>
                      ⇓
                      {(
                        (((topDurations[index] - availability) * availabilityMultiplier) /
                          availability) *
                        availabilityMultiplier
                      ).toFixed(2) * 100}
                      %
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
          <tr>
            <td>Total</td>
            <td>{totalDuration}</td>
            <td>100%</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    )
  );
}