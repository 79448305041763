import placeholder from "../../assets/150x150.png";
import { DateTime } from "luxon";
import { getTeamInfo } from "../../util/helpers";
import { useLoaderData } from "react-router-dom";

export async function profileTeamLoader() {
  try {
    const res = await getTeamInfo();
    return res;
  } catch (error) {
    console.error(error);
    alert("An error occured while fetching the team.");
  }
}

export default function ProfileTeam() {
  const team = useLoaderData();

  return (
    <>
      <div className="buffer-5vh" />
      <div className="card card-body">
        <h2 className="card-title mb-4 text-center">Team</h2>
        <div className="row">
          {team.map((user) => (
            <div className="col-md-3" key={user.id}>
              <div className="card" style={{ width: "18rem", margin: "10px" }}>
                <img src={placeholder} className="card-img-top" alt="User" />
                <div className="card-body">
                  <h5 className="card-title">{`${user.name} ${user.surname}`}</h5>
                  <p className="card-text">Email: {user.email}</p>
                  <p className="card-text">
                    Availability: {user.availability} hours/week
                  </p>
                  <p className="card-text">
                    Joined on:{" "}
                    {DateTime.fromISO(user.created_at).toFormat(
                      "LLL dd, yyyy HH:mm",
                    )}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
