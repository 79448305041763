import React from 'react';
import { withAuthentication, withRouter } from '../wrappers';
import { Register, Login } from './index';
import { jira } from '../util/api';

class Translation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ready: false
        }
    }
    componentDidMount() {
       jira.get('/rest/api/3/search?').then(res=>{
            console.log(res.json())
        })
    }
    render() {
        return (<>
        
        </>)
    }
}

export default withRouter(withAuthentication(Translation));