import {
  Form,
  Link,
  json,
  useLoaderData,
  useLocation,
  useNavigation,
} from "react-router-dom";

import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { get } from "../../util/api";
import { cloneDeep } from "lodash";
import { updateProfile } from "../../util/helpers";
import { useDispatch } from "react-redux";
import { login } from "../../actions/auth";

const getUserDetails = async () => {
  try {
    const res = await get("/api/account/my");
    const userDetails = res.entities[0];
    return userDetails;
  } catch (error) {
    throw error;
  }
};

export async function profileDetailsLoader() {
  try {
    const userDetails = await getUserDetails();
    return userDetails;
  } catch (error) {
    console.error(error);
  }
}

export async function profileDetailsAction({ request }) {
  let formData = await request.formData();
  const payload = {};

  for (const pair of formData.entries()) {
    if (
      pair[0] === "currency" ||
      pair[0] === "availability" ||
      pair[0] === "id"
    ) {
      pair[1] = parseInt(pair[1]);
    }

    payload[pair[0]] = pair[1];
  }

  try {
    const res = await updateProfile(payload);
    return json(res, { status: 200 });
  } catch (error) {
    throw json({ message: "Error updating user", error }, { status: 500 });
  }
}

export default function ProfileDetails() {
  const dispatch = useDispatch();

  let { state } = useLocation();
  const { state: navState } = useNavigation();

  const storedUser = useLoaderData();
  const storedUserDeepCopy = cloneDeep(storedUser);

  const [user, setUser] = useState(storedUserDeepCopy);

  useEffect(() => {
    if (state && state.refresh) {
      dispatch(login(storedUserDeepCopy));
    }
  }, [storedUserDeepCopy, dispatch, state]);

  const handleUserChange = (e) => {
    const { id, value } = e.target;

    const newUser = cloneDeep(user);
    newUser[id] = value;
    setUser(newUser);
  };

  return (
    <Form method="post" state={{ refresh: true }} className="card card-body">
      <h2 className="card-title mb-4 text-center">Profile Details</h2>
      <div className="row g-2">
        <div className="col-md">
          <div className="form-floating  mb-1">
            <input hidden readOnly name="id" value={user.id} />
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={user.email}
              readOnly
            />
            <label htmlFor="email">Email address</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating  mb-1">
            <input
              type="text"
              className="form-control"
              id="floatingInputGrid"
              value={DateTime.fromISO(user.created_at).toFormat("LLLL d, yyyy")}
              readOnly
              disabled
            />
            <label htmlFor="floatingInputGrid">Registered on</label>
          </div>
        </div>
      </div>
      <div className="row g-2">
        <div className="col-md">
          <div className="form-floating  mb-1">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={user.name}
              onChange={handleUserChange}
            />
            <label htmlFor="name">Name</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating  mb-1">
            <input
              type="text"
              className="form-control"
              id="surname"
              name="surname"
              value={user.surname}
              onChange={handleUserChange}
            />
            <label htmlFor="surname">Surname</label>
          </div>
        </div>
      </div>

      <div className="row g-2 mb-3">
        <div className="col-md">
          <div className="form-floating  mb-1">
            <input
              type="number"
              className="form-control"
              id="availability"
              name="availability"
              value={user.availability}
              onChange={handleUserChange}
            />
            <label htmlFor="availability">Availability (Hours per week)</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating  mb-1">
            <select
              className="form-select"
              id="currency"
              aria-label=""
              name="currency"
              value={user.currency}
              onChange={handleUserChange}
            >
              <option value="0">USD ($)</option>
              <option value="1">EUR (€)</option>
              <option value="2">GPB (£)</option>
            </select>
            <label htmlFor="currency">Currency</label>
          </div>
        </div>
      </div>
      <div className="row mb-3 mt-3">
        <h2 className="card-title mb-4 text-center">Invoice Details</h2>
        <div className="col">
          <div className="form-floating mb-1">
            <input
              type="text"
              className="form-control"
              id="companyAlias"
              name="companyAlias"
              value={user.companyAlias}
              onChange={handleUserChange}
            />
            <label htmlFor="name">Company Alias (optional, for invoices)</label>
          </div>
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="fullAddress"
              name="fullAddress"
              value={user.fullAddress}
              onChange={handleUserChange}
            />
            <label htmlFor="surname">
              Full Address (optional, for invoices)
            </label>
          </div>
        </div>
      </div>
      <button
        disabled={navState.submitting}
        className="btn btn-primary mb-1"
        type="submit"
      >
        Update
      </button>
      <Link className="btn btn-warning" to="/logout">
        Logout
      </Link>
    </Form>
  );
}
