import { useState } from "react";
import { changePwd } from "../../util/helpers";

export default function ProfileChangePwd() {
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setPasswords((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { oldPassword, newPassword, newPasswordRepeat } = passwords;

    if (newPassword !== newPasswordRepeat) {
      alert("New passwords do not match");
      return;
    }

    try {
      const res = await changePwd(oldPassword, newPassword);

      if (res.status !== 403) {
        alert("Password changed successfully");
      } else {
        alert("Old password doesn't match.");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      alert("An error occurred while changing the password. Please try again.");
    } finally {
      setPasswords({ oldPassword: "", newPassword: "", newPasswordRepeat: "" });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="card card-body">
      <h2 className="card-title mb-4 text-center">Change password</h2>
      <div className="row">
        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="oldPassword"
            value={passwords.oldPassword}
            onChange={handleChange}
            required
          />
          <label htmlFor="pwd">Old password</label>
        </div>
      </div>
      <div className="row">
        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="newPassword"
            value={passwords.newPassword}
            onChange={handleChange}
            required
          />
          <label htmlFor="pwd">New password</label>
        </div>
      </div>
      <div className="row">
        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="newPasswordRepeat"
            value={passwords.newPasswordRepeat}
            onChange={handleChange}
            required
          />
          <label htmlFor="pwd">New password (repeat)</label>
        </div>
      </div>
      <div className="buffer-5vh" />
      <button type="submit" className="btn btn-primary">
        Change Password
      </button>
    </form>
  );
}
