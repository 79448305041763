import { REHYDRATE } from 'redux-persist/es/constants';

const initialState = {
  projectName: "",
  projectId: 0,
  taskName: "",
  taskId: 0,
  departmentId: null,
  orgId: null,
  serviceId: null,
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      // TRAC-56: I think since we are using "...state" in our other actions,
      // we need to have a custom rehydrate action to properly rehydrate the state
      return {
        ...state,
        ...(action.payload ? action.payload.task : {}),
      };

    case "PROJECTNAME":
      return {
        ...state,
        projectName: action.payload,
      };
    case "PROJECTID":
      return {
        ...state,
        projectId: action.payload,
      };
    case "TASKNAME":
      return {
        ...state,
        taskName: action.payload,
      };
    case "TASKID":
      return {
        ...state,
        taskId: action.payload,
      };
    case "DEPARTMENTID":
      return {
        ...state,
        departmentId: action.payload,
      };
    case "ORGID":
      return {
        ...state,
        orgId: action.payload,
      };
    case "SERVICEID":
      return {
        ...state,
        serviceId: action.payload,
      };
    default:
      return { ...state };
  }
};

export default taskReducer;
