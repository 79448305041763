import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { DateTime } from "luxon";
import { roundTo } from "../../util/helpers";

export default function TimeBreakdownTable({ fancykeyname, keyname, topNBuckets }) {
  const { entries } = useLoaderData();
  const [topLabels, setTopLabels] = useState([]);
  const [topDurations, setTopDurations] = useState([]);
  const [totalDuration, setTotalDuration] = useState(0);

  const update = () => {
    let categoryName = keyname || "project";

    if (categoryName === "organization") {
      categoryName = "department.org";
    }

    const entryDurations = {};
    let totalDuration = 0;

    entries.forEach((entry) => {
      const uniqueKey = getUniqueKey(entry, categoryName);
      const duration = calculateDuration(entry);
      entryDurations[uniqueKey] = roundTo(
        (entryDurations[uniqueKey] || 0) + duration,
        2,
      );
      totalDuration += duration;
    });

    const { topLabels, topDurations, otherDuration } = selectTopDurations(
      entryDurations,
      topNBuckets,
    );
    if (otherDuration > 0) {
      topLabels.push("other");
      topDurations.push(otherDuration);
    }

    setTopLabels(topLabels);
    setTopDurations(topDurations);
    setTotalDuration(roundTo(totalDuration, 2));
  };

  const calculateDuration = (entry) => {
    const start = DateTime.fromISO(entry.start_time);
    const end = DateTime.fromISO(entry.end_time);
    const duration = end.diff(start, "hours");
    return duration.hours;
  };

  const selectTopDurations = (durations, maxCount) => {
    const entries = Object.entries(durations).sort((a, b) => b[1] - a[1]);
    const topEntries = entries.slice(0, maxCount);
    const otherEntries = entries.length > maxCount ? entries.slice(maxCount) : [];
    const otherDuration = otherEntries.reduce(
      (sum, [, duration]) => sum + duration,
      0,
    );

    return {
      topLabels: topEntries.map(([key]) => key),
      topDurations: topEntries.map(([, duration]) => duration),
      otherDuration,
    };
  };

  const getUniqueKey = (entry, categoryName) => {
    let uniqueKey = entry[categoryName]?.name;

    const categoryToArray = categoryName.split(".");
    if (categoryToArray.length !== 1) {
      const oneLevelDown = categoryToArray[0];
      const twoLevelsDown = categoryToArray[1];
      uniqueKey = entry[oneLevelDown][twoLevelsDown]["name"];
    }

    return uniqueKey;
  };

  useEffect(() => {
    update();
  }, [entries]);

  return (
    topLabels.length > 0 && (
      <table className="fixed-width-table">
        <thead>
          <tr>
            <th>{fancykeyname || keyname}</th>
            <th>Hours</th>
            <th>% of total hours</th>
          </tr>
        </thead>
        <tbody>
          {topLabels.map((label, index) => {
            const percent = (topDurations[index] / totalDuration) * 100;

            return (
              <tr key={label}>
                <td>{label}</td>
                <td>{topDurations[index]}</td>
                <td>{percent.toFixed(2)}%</td>
              </tr>
            );
          })}
          <tr>
            <td>Total</td>
            <td>{totalDuration}</td>
            <td>100%</td>
          </tr>
        </tbody>
      </table>
    )
  );
}
