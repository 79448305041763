import { Modal } from "react-bootstrap";
import Calendar from "react-calendar";
import TimePicker from "react-time-picker";

import "react-calendar/dist/Calendar.css";
import { DateTime } from "luxon";

export default function DateModal({
  modalVisible,
  // invalidDate,
  manualStartTime,
  setManualStartTime,
  manualEndTime,
  setManualEndTime,
  closeModal,
}) {

  return (
    <Modal
      className="task-date-picker"
      show={modalVisible}
      onHide={closeModal}
      dialogClassName="modal-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>Task Date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {invalidDate ? (
          <div className="alert alert-danger">
            Start date must be before end date
          </div>
        ) : (
          <></>
        )} */}
        <div className="col">
          <div className="row">
            <b>Start</b>
            <div className="col">
              <Calendar
                value={manualStartTime}
                maxDate={manualEndTime}
                onChange={setManualStartTime}
              />
            </div>
            <div className="col">
              <TimePicker
                format={"HH:mm"}
                onChange={setManualStartTime}
                value={manualStartTime}
                isOpen={true}
                maxTime={manualEndTime}
                // closeClock={false}
                shouldCloseClock={() => false}
              />
            </div>
          </div>
          <div className="row" style={{ height: "50px", width: "50px" }}></div>
          <div className="row">
            <b>End</b>
            <div className="col">
              <Calendar
                value={manualEndTime}
                onChange={setManualEndTime}
                minDate={manualStartTime}
                maxDate={DateTime.now().toJSDate()}
              />
            </div>
            <div className="col">
              <TimePicker
                format={"HH:mm"}
                onChange={setManualEndTime}
                value={manualEndTime}
                isOpen={true}
                minTime={manualStartTime}
                // maxTime={DateTime.now().toJSDate()}
                // closeClock={false}
                shouldCloseClock={() => false}
              />
            </div>
          </div>
        </div>
        <button className="btn btn-success btn-timer" onClick={closeModal}>
          Set Time
        </button>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
