import { useEffect, useState } from "react";
import { Form, useLocation } from "react-router-dom";

export default function NewServiceCard({ symbol, userId }) {
  // newservice state moved to its own component and passed as payload to parent on submit
  const [newService, setNewService] = useState({
    name: "",
    description: "",
    is_hourly: 1,
    hourly_rate: 0,
    new_word_rate: 0,
    unverified_word_rate: 0,
  });

  let { state } = useLocation();

  useEffect(() => {
    if (state && state.refresh) {
      setNewService({
        name: "",
        description: "",
        is_hourly: 1,
        hourly_rate: 0,
        new_word_rate: 0,
        unverified_word_rate: 0,
      });
    }
  }, [state]);

  const handleChange = (e) => {
    const { id, value } = e.target;

    setNewService((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSelect = (e) => {
    const { value } = e.target;
    setNewService((prevState) => {
      const updates = {
        is_hourly: value,
        new_word_rate: value === "1" ? 0 : prevState.new_word_rate,
        unverified_word_rate:
          value === "1" ? 0 : prevState.unverified_word_rate,
        hourly_rate: value !== "1" ? 0 : prevState.hourly_rate,
      };

      return {
        ...prevState,
        ...updates,
      };
    });
  };

  return (
    <Form state={{ refresh: true }} method="post" className="card card-body">
      <h3>New Service</h3>
      <div className="row">
        <div className="col-md">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={newService.name}
              onChange={handleChange}
            />
            <input type="text" name="account_id" readOnly value={userId} hidden />
            <label htmlFor="name">Service Name</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="description"
              name="description"
              value={newService.description}
              onChange={handleChange}
            />
            <label htmlFor="desc">Service Description</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <div className="form-floating">
            <select
              className="form-select"
              id="is_hourly"
              aria-label=""
              name="is_hourly"
              value={newService.is_hourly}
              onChange={handleSelect}
            >
              <option value="1">{symbol}/hr Time-Driven Service</option>
              <option value="0">Translation Service</option>
            </select>
            <label htmlFor="is_hourly">Service type</label>
          </div>
        </div>
        {newService.is_hourly == 1 ? (
          <div className="col">
            <div className="form-floating">
              <input
                type="number"
                min="0"
                className="form-control"
                id="hourly_rate"
                name="hourly_rate"
                value={newService.hourly_rate}
                onChange={handleChange}
              />
              <label htmlFor="hrate">Rate (Per Hour)</label>
            </div>
          </div>
        ) : (
          <>
            <div className="col">
              <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  id="new_word_rate"
                  name="new_word_rate"
                  value={newService.new_word_rate}
                  onChange={handleChange}
                />
                <label htmlFor="hrate">Rate (New Word)</label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  id="unverified_word_rate"
                  name="unverified_word_rate"
                  value={newService.unverified_word_rate}
                  onChange={handleChange}
                />
                <label htmlFor="hrate">Rate (Unverified Word)</label>
              </div>
            </div>
          </>
        )}
      </div>
      <button type="submit" className="btn btn-primary">
        New
      </button>
    </Form>
  );
}
