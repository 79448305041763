import React, { useEffect, useState } from "react";
import { toSymbol } from "../../util/helpers";
import { useSelector } from "react-redux";
import { useLoaderData } from "react-router-dom";

export default function TotalInvoice() {
  const {
    hourlyServicesSummary,
    translationServicesSummary,
    selectedFrame,
    totalCost,
  } = useLoaderData();
  let { currency, availability: defaultAvailability } = useSelector(
    (state) => state.auth.user,
  );
  const [availability, setAvailability] = useState(null);
  const symbol = toSymbol(currency);

  useEffect(() => {
    if (selectedFrame === 0 || selectedFrame === 1) {
      // Monthly
      setAvailability((defaultAvailability *= 4));
    } else if (selectedFrame === 4) {
      // Yearly
      setAvailability((defaultAvailability *= 52));
    } else {
      setAvailability(defaultAvailability);
    }
  }, [selectedFrame]);

  return (
    (
      <div style={{ height: "475px" }}>
        {" "}
        <span>
          <b>Hourly Services: </b>{" "}
        </span>
        {Object.values(hourlyServicesSummary.services).map((service) => {
          if (!service.name) {
            return <></>;
          }

          return (
            <div className="row">
              <span>
                {" "}
                {service.name}: {service.hourlyRate}
                {symbol} x {service.hours.toFixed(2)} hours. ={" "}
                {service.cost.toFixed(2)}
                <br />
              </span>
            </div>
          );
        })}
        <div className="row">
          <span>
            <b>Total Hours: </b>{" "}
            {hourlyServicesSummary.durationObject.as("hours").toFixed(2)} hours.
            ({hourlyServicesSummary.durationObject.toFormat("hh:mm:ss")})
            {availability ? (
              hourlyServicesSummary.durationObject.as("hours") >
              availability ? (
                <span style={{ color: "#00aa00" }}>
                  ⇑+
                  {(
                    ((hourlyServicesSummary.durationObject.as("hours") -
                      availability) /
                      availability) *
                    100
                  ).toFixed(2)}
                  %{" "}
                </span>
              ) : (
                <span style={{ color: "#aa0000" }}>
                  ⇓
                  {(
                    (hourlyServicesSummary.durationObject.as("hours") -
                      availability) /
                    availability
                  ).toFixed(2) * 100}
                  %
                </span>
              )
            ) : (
              <></>
            )}
          </span>
        </div>
        <div className="row">
          <span>
            <b>Total Cost: </b>
            {symbol}
            {totalCost}
          </span>
        </div>
        <div className="mb-3" />
        <div className="row">
          <span>
            <span style={{ fontSize: "128px", color: "#008800" }}>
              {symbol}
            </span>
            <b style={{ fontSize: "70px", color: "#22aa22" }}>{totalCost}</b>
          </span>
        </div>
      </div>
    )
  );
}
