import { useState } from "react";

import { Trash } from "lucide-react";
import { updateServices } from "../util/helpers";
import { useNavigate } from "react-router-dom";

export default function ServiceCard({
  serviceObject,
  // updateServices,
  userId,
  //   Combining with the NewServiceCard is a possibility
  isNewService = false,
}) {
  // Service cards moved to separate component
  // They are now editable and controlled
  // If the edited value differs from the initial state, an update button will appear, which will make a PATCH request
  //   An UNDO button also appears
  const [service, setService] = useState(serviceObject);
  const navigate = useNavigate();

  const generateInputId = (name) =>
    `service-${isNewService ? "new" : service.id}-${name}`;

  const handleChange = (e) => {
    let { name, value } = e.target;
    if(["hourly_rate", "new_word_rate", "unverified_word_rate"].includes(name) && value < 0)
      value = 0;
    setService((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUndoChanges = () => {
    setService(serviceObject);
  };

  const handleSubmit = (e, service) => {
    e.preventDefault();
    updateServices(service, userId);
    navigate("/profile/services", { replace: true, state: { refresh: true } });
  };

//   TODO
  const handleDelete = (e) => {
    console.log("deleted");
  };

  //   TODO: Still cannot compare input type number
  const haveServicesChanged = () => {
    return JSON.stringify(serviceObject) !== JSON.stringify(service);
  };

  return (
    <form className={`card card-body ${!isNewService && "mb-3"}`}>
      <h3>
        {isNewService
          ? "New Service"
          : service.is_hourly
            ? "Time-Driven Service"
            : "Translation Service"}
      </h3>

      <div className="row">
        {/* SERVICE NAME */}
        <div className={!isNewService ? "col-md-4" : "col-md"}>
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              name="name"
              id={generateInputId("name")}
              value={service.name}
              onChange={handleChange}
            />
            <label htmlFor={generateInputId("name")}>Service Name</label>
          </div>
        </div>

        {/* SERVICE DESCRIPTION */}
        <div className={!isNewService ? "col-md-4" : "col-md"}>
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              name="description"
              id={generateInputId("description")}
              value={service.description}
              onChange={handleChange}
            />
            <label htmlFor={generateInputId("description")}>
              Service Description
            </label>
          </div>
        </div>

        {/* SERVICE RATES */}
        {service.is_hourly ? (
          <div className="col-md">
            <div className="form-floating">
              <input
                type="number"
                className="form-control"
                name="hourly_rate"
                id={generateInputId("hourly_rate")}
                value={service.hourly_rate}
                onChange={handleChange}
              />
              <label htmlFor={generateInputId("hourly_rate")}>
                Rate (Per Hour)
              </label>
            </div>
          </div>
        ) : (
          <>
            <div className="col-md">
              <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  name="new_word_rate"
                  id={generateInputId("new_word_rate")}
                  value={service.new_word_rate || ""}
                  onChange={handleChange}
                />
                <label htmlFor={generateInputId("new_word_rate")}>
                  Rate (New Word)
                </label>
              </div>
            </div>
            <div className="col-md">
              <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  name="unverified_word_rate"
                  id={generateInputId("unverified_word_rate")}
                  value={service.unverified_word_rate || ""}
                  onChange={handleChange}
                />
                <label htmlFor={generateInputId("unverified_word_rate")}>
                  Rate (Unverified)
                </label>
              </div>
            </div>
          </>
        )}

        {/* TODO
        <Trash onClick={handleDelete} /> */}

        {haveServicesChanged(serviceObject, service) && (
          <>
            {/* <button
              type="submit"
              className="btn btn-primary mt-3"
              onClick={() => handleSubmit(e, service)}
            >
              Update
            </button> */}
            <button
              type="button"
              className="btn btn-primary mt-3"
              onClick={handleUndoChanges}
            >
              Undo Changes
            </button>
          </>
        )}
      </div>
    </form>
  );
}
