import Login from "./Login";
import Register from "./Register";

export default function Home() {
  return (
    <div className="container-xxl">
      <div className="buffer-5vh" />
      <div className="row justify-content-center">
        <div className="col">
          <div className="card card-body split-page-container">
            <h2 className="card-title mb-4 text-center">Register</h2>
            <Register />
          </div>
        </div>
        <div className="col">
          <div className="card card-body split-page-container">
            <h2 className="card-title mb-4 text-center">Login</h2>
            <Login />
          </div>
        </div>
      </div>
    </div>
  );
}
