import { CSSTransition } from "react-transition-group";
import Calendar from "react-calendar";
import TimePicker from "react-time-picker";

import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "../../scss/clock-fix.scss";
import "../../scss/calendar-fix.scss";
import { DateTime } from "luxon";

export default function EditForm({
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  onCancel,
}) {
  return (
    <div className="row g-3 old-task">
      <input
        type="text"
        name="startTime"
        value={startTime.toISOString()}
        hidden
        readOnly
      />
      <input
        type="text"
        name="endTime"
        value={endTime.toISOString()}
        hidden
        readOnly
      />
      <div className="col-sm-2 p-5 pb-3 pt-3 ">
        <div className="row mb-3 mt-5">
          <button
            type="submit"
            name="intent"
            value="patch"
            className="btn btn-primary"
          >
            Save
          </button>
        </div>
        <div className="row mb-3 ">
          <button type="button" className="btn btn-danger" onClick={onCancel}>
            Cancel
          </button>
        </div>
        <div className="row mb-3 ">
          <button
            type="submit"
            name="intent"
            value="delete"
            className="btn btn-warning"
          >
            Delete
          </button>
        </div>
      </div>

      <div className="col">
        <b>Start </b>
        {/* {invalidDate ? (
            <div className="alert alert-danger">
              Start date must be before end date
            </div>
          ) : (
            <></>
          )} */}
        <Calendar value={startTime} maxDate={endTime} onChange={setStartTime} />
      </div>
      <div className="col">
        <TimePicker
          format={"HH:mm"}
          onChange={setStartTime}
          value={startTime}
          isOpen={true}
          // maxTime={endTime}
          // closeClock={true}
          shouldCloseClock={() => false}
        />
      </div>

      <div className="col">
        <b> Stop </b>
        <Calendar
          value={endTime}
          minDate={startTime}
          maxDate={DateTime.now().toJSDate()}
          onChange={setEndTime}
        />
      </div>
      <div className="col">
        <TimePicker
          format={"HH:mm"}
          onChange={setEndTime}
          value={endTime}
          isOpen={true}
          // maxTime={DateTime.now().toJSDate()}
          minTime={startTime}
          // closeClock={true}
          shouldCloseClock={() => false}
        />
      </div>
    </div>
  );
}
