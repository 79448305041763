import { useEffect } from "react";

import { json, useLoaderData } from "react-router-dom";

import { CurrentTask } from "../components";
import ServiceWarningPopup from "../components/Dashboard/ServiceWarningPopUp";
import TasksByDate from "../components/Dashboard/TasksByDate";

import {
  loadOrganizations,
  loadMyRecentTasks,
  getPaymentInfoHourly,
} from "../util/helpers";
import { del, get, post } from "../util/api";
import { DateTime } from "luxon";

const getTimerRes = async () => {
  try {
    const timerRes = await get("/api/timer");
    if (timerRes.active) {
      return {
        timerStatus: true,
        startTime: timerRes.start_time,
      };
    } else {
      return {
        timerStatus: false,
        startTime: null,
      };
    }
  } catch (error) {
    throw error;
  }
};

const groupEntriesByDate = (entries) => {
  const entriesByDate = {};

  entries.forEach((entry) => {
    const dateStr = DateTime.fromISO(entry.start_time).toISODate();

    if (!entriesByDate[dateStr]) {
      entriesByDate[dateStr] = { date: dateStr, entries: [] };
    }

    entriesByDate[dateStr].entries.push(entry);
  });

  const groupedEntriesArray = Object.values(entriesByDate);
  groupedEntriesArray.sort(
    (a, b) => DateTime.fromISO(b.date) - DateTime.fromISO(a.date),
  );

  return groupedEntriesArray;
};

export const dashboardLoader = async () => {
  try {
    const orgs = await loadOrganizations();
    const entries = await loadMyRecentTasks();
    const entriesByDate = groupEntriesByDate(entries);
    const services = await getPaymentInfoHourly();
    const timerRes = await getTimerRes();

    return { orgs, entriesByDate, services, timerRes };
  } catch (error) {
    console.error(error);
  }
};

// ---ACTION---
const projectAutocomplete = async (projectName) => {
  const projectsArray = await get(
    "/api/team/autocomplete/project?name=" + projectName,
  );
  const foundProject = projectsArray.find(
    (project) => project.name === projectName,
  );

  if (foundProject) {
    return foundProject.id;
  } else {
    return null;
  }
};

const taskAutocomplete = async (taskName) => {
  const tasksArray = await get("/api/team/autocomplete/task?name=" + taskName);
  const foundTask = tasksArray.find((task) => task.name === taskName);

  if (foundTask) {
    return foundTask.id;
  } else {
    return null;
  }
};

export const dashboardAction = async ({ request }) => {
  let formData = await request.formData();
  const intent = formData.get("intent");
  const taskName = formData.get("taskName");
  const projectName = formData.get("projectName");
  let projectId = parseInt(formData.get("projectId"));
  let taskId = parseInt(formData.get("taskId"));
  const errors = {};

  // Validation stage
  if (taskName === "") {
    errors.taskName = "Please, provide a value for task name.";
  }

  if (projectName === "") {
    errors.projectName = "Please, provide a value for project name.";
  }

  if (Object.keys(errors).length) {
    return errors;
  }

  // Async stage
  if (intent === "start") {
    try {
      const res = await post("/api/timer/start");
      return json(res, { status: 200 });
    } catch (error) {
      throw json({ message: "Error starting task", error }, { status: 500 });
    }
  }

  if (intent === "post" || intent === "postManual") {
    const departmentId = parseInt(formData.get("departmentId"));
    const orgId = parseInt(formData.get("orgId"));
    const serviceId = parseInt(formData.get("serviceId"));

    try {
      const foundProjectId = await projectAutocomplete(projectName);
      const foundTaskId = await taskAutocomplete(taskName);

      if (foundProjectId) {
        projectId = foundProjectId;
      }

      if (foundTaskId) {
        taskId = foundTaskId;
      }

      const entry = {
        description: "",
        projectId,
        projectName,
        taskId,
        taskName,
        departmentId,
        orgId,
        serviceId,
      };

      if (intent === "postManual") {
        const manualStartTime = formData.get("manualStartTime");
        const manualEndTime = formData.get("manualEndTime");

        entry.startTime = manualStartTime;
        entry.endTime = manualEndTime;

        const res = await post("/api/timer/manual", entry);
        return json(res, { status: 200 });
      }

      const res = await post("/api/timer/stop", entry);
      return json(res, { status: 200 });
    } catch (error) {
      throw json({ message: "Error recording entry", error }, { status: 500 });
    }
  }

  if (intent === "patch") {
    const payload = {};

    for (const pair of formData.entries()) {
      payload[pair[0]] = pair[1];
    }

    delete payload.hour24;
    delete payload.minute;
    delete payload.time;
    delete payload.intent;

    try {
      const foundProjectId = await projectAutocomplete(projectName);
      const foundTaskId = await taskAutocomplete(taskName);

      if (foundProjectId) {
        projectId = foundProjectId;
      }

      if (foundTaskId) {
        taskId = foundTaskId;
      }

      payload.projectId = projectId;
      payload.taskId = taskId;

      const res = await post("/api/timer/edit", payload);
      return json(res, { status: 200 });
    } catch (error) {
      throw json({ message: "Error updating task", error }, { status: 500 });
    }
  }

  if (intent === "cancel") {
    try {
      const res = await post("/api/timer/cancel");
      return json(res, { status: 200 });
    } catch (error) {
      throw json({ message: "Error cancelling task", error }, { status: 500 });
    }
  }

  if (intent === "delete") {
    const entryId = formData.get("entryId");
    const res = await del("/api/timer/" + entryId);
    return json(res, { status: 200 });
  }

  throw json({ message: "Invalid intent" }, { status: 400 });
};

export default function Dashboard() {
  const { services } = useLoaderData();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search).get("theme");
    if (query) document.body.setAttribute("data-bs-theme", query);
  }, []);

  return (
    <div className="container-xxl mt-3">
      {!Object.keys(services).length ? (
        <ServiceWarningPopup />
      ) : (
        <>
          <CurrentTask />
          <TasksByDate />
        </>
      )}
    </div>
  );
}
