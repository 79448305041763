import axios from "axios";
import config from "../config";

const api = axios.create({
  baseURL: config.backendURL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const jira = axios.create({
  baseURL: config.jiraHost,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

function handleApiResponse(response) {
  if (response.status === 401) {
    window.location.href = "/login";
    return false;
  }
  return response.data;
}

function handleApiError(error) {
  if (error.response?.status === 401) {
    window.location.href = "/login";
    return false;
  }
  return error.response?.data;
}

export const get = async (endpoint) => {
  try {
    const response = await api.get(endpoint);
    return handleApiResponse(response);
  } catch (error) {
    // Handle error
    handleApiError(error);
  }
};

export const post = async (endpoint, data = {}) => {
  try {
    const response = await api.post(endpoint, data);
    return handleApiResponse(response);
  } catch (error) {
    // Handle error
    handleApiError(error);
  }
};

export const put = async (endpoint, data) => {
  try {
    const response = await api.put(endpoint, data);
    return handleApiResponse(response);
  } catch (error) {
    // Handle error
    handleApiError(error);
  }
};

export const patch = async (endpoint, data) => {
  try {
    const response = await api.patch(endpoint, data);
    return handleApiResponse(response);
  } catch (error) {
    // Handle error
    handleApiError(error);
  }
};

export const del = async (endpoint, data) => {
  try {
    const response = await api.delete(endpoint, data);
    return handleApiResponse(response);
  } catch (error) {
    // Handle error
    handleApiError(error);
  }
};
