import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { DateTime } from "luxon";
import { roundTo } from "../../util/helpers";

export default function CostBreakdownTable({ fancykeyname, keyname, topNBuckets }) {
  const { entries } = useLoaderData();
  const [topLabels, setTopLabels] = useState([]);
  const [topCosts, setTopCosts] = useState([]);
  const [totalCost, setTotalCost] = useState(0);

  const update = () => {
    let categoryName = keyname || "project";

    if (categoryName === "organization") {
      categoryName = "department.org";
    }

    const entryCosts = {};
    let totalCost = 0;

    entries.forEach((entry) => {
      const uniqueKey = getUniqueKey(entry, categoryName);
      const cost = calculateCost(entry);
      entryCosts[uniqueKey] = roundTo((entryCosts[uniqueKey] || 0) + cost, 2);
      totalCost += cost;
    });

    const { topLabels, topCosts, otherCost } = selectTopCosts(entryCosts, topNBuckets);
    if (otherCost > 0) {
      topLabels.push("other");
      topCosts.push(otherCost);
    }

    setTopLabels(topLabels);
    setTopCosts(topCosts);
    setTotalCost(roundTo(totalCost, 2));
  };

  const calculateCost = (entry) => {
    const start = DateTime.fromISO(entry.start_time);
    const end = DateTime.fromISO(entry.end_time);
    const duration = end.diff(start, "hours");
    const cost = duration.hours * entry.service.hourly_rate;
    return cost;
  };

  const selectTopCosts = (costs, maxCount) => {
    const entries = Object.entries(costs).sort((a, b) => b[1] - a[1]);
    const topEntries = entries.slice(0, maxCount);
    const otherEntries = entries.length > maxCount ? entries.slice(maxCount) : [];
    const otherCost = otherEntries.reduce((sum, [, cost]) => sum + cost, 0);

    return {
      topLabels: topEntries.map(([key]) => key),
      topCosts: topEntries.map(([, cost]) => cost),
      otherCost,
    };
  };

  const getUniqueKey = (entry, categoryName) => {
    let uniqueKey = entry[categoryName]?.name;

    const categoryToArray = categoryName.split(".");
    if (categoryToArray.length !== 1) {
      const oneLevelDown = categoryToArray[0];
      const twoLevelsDown = categoryToArray[1];
      uniqueKey = entry[oneLevelDown][twoLevelsDown]["name"];
    }

    return uniqueKey;
  };

  useEffect(() => {
    update();
  }, [entries]);

  return (
    topLabels.length > 0 && (
      <table className="fixed-width-table">
        <thead>
          <tr>
            <th>{fancykeyname || keyname}</th>
            <th>Cost</th>
            <th>% of total cost</th>
          </tr>
        </thead>
        <tbody>
          {topLabels.map((label, index) => {
            const percent = (topCosts[index] / totalCost) * 100;

            return (
              <tr key={label}>
                <td>{label}</td>
                <td>{topCosts[index]}$</td>
                <td>{percent.toFixed(2)}%</td>
              </tr>
            );
          })}
          <tr>
            <td>Total</td>
            <td>{totalCost}$</td>
            <td>100%</td>
          </tr>
        </tbody>
      </table>
    )
  );
}