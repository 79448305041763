import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get } from "../util/api";
import { DateTime, Duration } from "luxon";
import {
  OldTasksTable,
  HourHistogram,
  TimeDonut,
  TotalInvoice,
  CostDonut,
} from "../components";
import {
  setPrintParams,
  getTeamInfo,
  removePrintParams,
  loadRecentTasks,
} from "../util/helpers";
import "../assets/print.css";
import { redirect, useLoaderData, useSubmit } from "react-router-dom";
import InvoiceInfo from "../components/Invoice/InvoiceInfo";
import InvoiceSelect from "../components/Invoice/InvoiceSelect";
import InvoiceWarning from "../components/Invoice/InvoiceWarning";
import DonutFlavorPicker from "../components/Invoice/DonutFlavorPicker"; 
import Calendar from "react-calendar";
import TimeBreakdownTable from "../components/Invoice/TimeBreakdownTable";
import CostBreakdownTable from "../components/Invoice/CostBreakdownTable";


const topNBuckets = 6;

const colors = [
  "#FFD700", // Gold
  "#FFA07A", // Light Salmon
  "#FF69B4", // Hot Pink
  "#87CEEB", // Sky Blueh
  "#98FB98", // Pale Green
  "#9370DB", // Medium Purple
  "#FFE4B5", // Moccasin
  "#20B2AA", // Light Sea Green
  "#FF6347", // Tomato
  "#40E0D0", // Turquoise
  "#FF8C00", // Dark Orange
  "#BA55D3", // Medium Orchid
  "#00FA9A", // Medium Spring Green
  "#FF4500", // Orange Red
  "#AFEEEE", // Pale Turquoise
  "#FFDAB9", // Peachpuff
  "#00CED1", // Dark Turquoise
  "#DDA0DD", // Plum
  "#8FBC8F", // Dark Sea Green
  "#FFB6C1", // Light Pink
];

const getUserInfo = async () => {
  return new Promise((resolve, reject) => {
    get("/api/account/my").then((res) => {
      resolve(res.entities[0]);
    });
  });
};

const getTeam = async () => {
  try {
    const team = await getTeamInfo();
    return team;
  } catch (error) {
    throw error;
  }
};

const getServices = async (accountId) => {
  const user = getUserInfo()
  if(accountId==user.id){
    const res = await get("/api/service_type/my");

    return res.entities;

  }else{
    const res = await get(`/api/service_type/search?account_id=${accountId}`);
    return res.entities;
  }
};

const getEntries = async (startTime, stopTime, accountId, serviceId) => {
  const user = await getUserInfo();
  const now = DateTime.now();
  const startOfMonth = now.startOf("month");

  let localizedStartTime = startTime ? DateTime.fromISO(startTime).toISO() : startOfMonth.toISO();
  let localizedStopTime = stopTime ? DateTime.fromISO(stopTime).toISO() : now.toISO();

  let limitQuery = `start_time_ge=${startTime ? localizedStartTime : startOfMonth.toISO()}${stopTime ? "&start_time_lt=" + localizedStopTime : ""}&account_id=${accountId ? accountId : user.id}`;
  if(serviceId && serviceId !=-1) limitQuery += `&service_id=${serviceId}`;
  const entries = await loadRecentTasks(limitQuery);

  return entries || [];
};

const getInvoiceInfo = async (entries, startTime, stopTime) => {
  const user = await getUserInfo();

  const invhash =
    entries
      .map((task) => task.id)
      .sort()
      .join("-")
      .split("")
      .reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0) >>> (0).toString();

  const formatDateTime = (date) => DateTime.fromISO(date).toFormat("yyyyMMdd");

  const invoiceNumber = `${user.id}/${formatDateTime(startTime)}-${formatDateTime(stopTime)}/${user.invoiceIndex}`;

  return { invhash, invoiceNumber };
};

const getReducedServices = (entries) => {
  const hourlyServicesSummary = {
    durationObject: Duration.fromObject({}),
    totalCost: 0,
    services: {},
  };

  // Redundant for now
  const translationServicesSummary = {
    totalWords: 0,
    totalCost: 0,
  };

  entries.forEach((entry) => {
    if (entry.service?.is_hourly) {
      const duration = DateTime.fromISO(entry.end_time).diff(
        DateTime.fromISO(entry.start_time),
      );
      const hours = duration.as("hours");
      const cost = hours * entry.service.hourly_rate;

      if (!hourlyServicesSummary.services[entry.service.name]) {
        hourlyServicesSummary.services[entry.service.name] = {
          name: entry.service.name,
          hourlyRate: entry.service.hourly_rate,
          hours: 0,
          cost: 0,
          id:entry.service.id
        };
      }

      hourlyServicesSummary.services[entry.service.name].hours += hours;
      hourlyServicesSummary.services[entry.service.name].cost += cost;

      hourlyServicesSummary.durationObject =
        hourlyServicesSummary.durationObject.plus(duration);
      hourlyServicesSummary.totalCost += cost;
    } else if (entry.service?.is_hourly === false) {
      // Redundant for now
      translationServicesSummary.totalWords += 0;
      translationServicesSummary.totalCost += 0;
    }
  });

  const totalCost = (
    hourlyServicesSummary.totalCost + translationServicesSummary.totalCost
  ).toFixed(2);

  return { hourlyServicesSummary, translationServicesSummary, totalCost };
};

export async function invoiceLoader({ request }) {
  const url = new URL(request.url);
  const startTime = url.searchParams.get("start_time_ge");
  const stopTime = url.searchParams.get("start_time_lt");
  let accountId = url.searchParams.get("account_id");
  const selectedFrame = url.searchParams.get("selected_frame");
  const selectedService = url.searchParams.get("selected_service");

  if(!accountId){
    const user = await getUserInfo();
    accountId = user.id;
  }
  try {
    const team = await getTeam();
    const entries = await getEntries(startTime, stopTime, accountId, selectedService);
    const services = await getServices(accountId);
    const { invhash, invoiceNumber } = await getInvoiceInfo(
      entries,
      startTime,
      stopTime,
    );
    const { hourlyServicesSummary, translationServicesSummary, totalCost} =
      getReducedServices(entries);
    
    return {
      team,
      entries,
      invhash,
      invoiceNumber,
      accountId: accountId,
      selectedFrame: selectedFrame || 0,
      selectedService: selectedService || -1,
      services: services,
      hourlyServicesSummary,
      translationServicesSummary,
      totalCost,
    };
  } catch (error) {
    console.error(error);
  }
}

export async function invoiceAction({ request }) {
  const formData = await request.formData();
  const startTime = formData.get("startTime");
  const stopTime = formData.get("stopTime");
  const accountId = formData.get("accountId");
  const showUser = formData.get("showUser");
  const selectedFrame = formData.get("selectedFrame");
  const selectedService = formData.get("selectedService");
  
  // const url = new URL(request.url);
  // const startTime = url.searchParams.get("start_time_ge");
  // const stopTime = url.searchParams.get("start_time_lt");
  // const accountId = url.searchParams.get("account_id");

  const params = new URLSearchParams();
  if (startTime) params.append("start_time_ge", startTime);
  if (stopTime) params.append("start_time_lt", stopTime);
  if (accountId) params.append("account_id", accountId);
  if (selectedFrame) params.append("selected_frame", selectedFrame);
  if (selectedService) params.append("selected_service", selectedService);
  else params.append("selected_service", -1);

  let updatedUrl;
  if (showUser === "false") {
    updatedUrl = `/invoice?${params.toString()}`;
  } else {
    updatedUrl = `/team-statistics?${params.toString()}`;
  }

  return redirect(updatedUrl);
}

export default function Invoice({ showUser }) {
  let { entries, selectedFrame, accountId, services, selectedService } = useLoaderData();
  services = []
  const submit = useSubmit();

  const [state, setState] = useState({
    now: DateTime.now(),
    timeStart: DateTime.now().startOf("month"),
    timeStop: DateTime.now().endOf("month"),
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPrintParams();

    return () => {
      removePrintParams();
    };
  }, []);

  const mapLimitQuery = (selection) => {
    const now = DateTime.now();
    let start, stop;
    let customTimeSelectionEnabled = false;
    // The .plus() is a workaround, the api needs to be fixed further
    switch (selection) {
      case 0: // This month
        start = now.startOf("month");
        stop = now.endOf("month").plus({ days: 1 });
        break;
      case 1: // Last month
        start = now.minus({ months: 1 }).startOf("month");
        stop = now.minus({ months: 1 }).endOf("month").plus({ days: 1 });
        break;
      case 2: // This week (Monday to Sunday)
        start = now.startOf("week");
        stop = now.endOf("week").plus({ days: 1 });
        break;
      case 3: // Last week (Monday to Sunday)
        start = now.minus({ weeks: 1 }).startOf("week");
        stop = now.minus({ weeks: 1 }).endOf("week").plus({ days: 1 });
        break;
      case 4: // This year
        start = now.startOf("year");
        stop = now.plus({ days: 1 });
        break;
      case 5: // This week (Friday to Thursday)
        start = now.startOf("week").minus({ weeks: 1 }).plus({ days: 4 });
        stop = now.startOf("week").plus({ days: 4 });
        break;
      case 6:
        customTimeSelectionEnabled = true;
        break;
      default:
        start = now;
        stop = now;
    }

    return { start, stop, customTimeSelectionEnabled };
  };

  const handleSelect = async (e) => {
    const selectedValue = parseInt(e.target.value);
    let { start, stop, customTimeSelectionEnabled } = mapLimitQuery(selectedValue);

    if(customTimeSelectionEnabled){
      start = state.timeStart;
      stop = state.timeStop
    }

    const formData = new FormData();
    formData.append("startTime", start.toISO());
    formData.append("stopTime", stop.toISO());
    formData.append("accountId", accountId);
    formData.append("showUser", showUser);
    formData.append("selectedFrame", selectedValue);
    formData.append("selectedService", selectedService);

    submit(formData, {
      method: "post",
      action: !showUser ? "/invoice" : "/team-statistics",
    });

    setState({
      ...state,
      timeStart: start,
      timeStop: stop,
      customTimeSelectionEnabled: customTimeSelectionEnabled,
    });
  };

  const handleTeamMemberSelect = async (e) => {
    const accountId = parseInt(e.target.value, 10);
    let { start, stop } = mapLimitQuery(selectedFrame);
    const formData = new FormData();
    formData.append("startTime", start.toISO());
    formData.append("stopTime", stop.toISO());
    formData.append("accountId", accountId);
    formData.append("showUser", showUser);
    formData.append("selectedFrame", selectedFrame);
    formData.append("selectedService", -1);

    submit(formData, {
      method: "post",
      action: !showUser ? "/invoice" : "/team-statistics",
    });
    const services = await getServices(accountId);
    setState({
      ...state,
      accountId: accountId,
      services: services,
      selectedService: -1
    });
  };

  const handleServiceSelect = async (e) => {
    const selectedService = parseInt(e.target.value);

    const formData = new FormData();
    formData.append("startTime", state.timeStart.toISO());
    formData.append("stopTime", state.timeStop.toISO());
    formData.append("accountId", accountId);
    formData.append("showUser", showUser);
    formData.append("selectedFrame", selectedFrame);
    formData.append("selectedService", selectedService);

    submit(formData, {
      method: "post",
      action: !showUser ? "/invoice" : "/team-statistics",
    });

    setState({
      ...state,
      selectedService: selectedService,
    });
  };

  const handleDonutFlavor = async (flavor) => {
    if(flavor=='Time')
      setState({
        ...state,
        DonutFlavor: TimeDonut,
        BreakdownFlavor: TimeBreakdownTable,
      });
    else if(flavor=='Cost')
      setState({
        ...state,
        DonutFlavor: CostDonut,
        BreakdownFlavor: CostBreakdownTable,
      });
  }

  const setCustomStart = async (date) => {
    const startTime = date.toISOString();
    setState((prevState) => ({
      ...prevState,
      timeStart: DateTime.fromISO(startTime),
    }));
  
    const formData = new FormData();
    formData.append("startTime", startTime);
    formData.append("stopTime", state.timeStop.toISO());
    formData.append("accountId", accountId);
    formData.append("showUser", showUser);
    formData.append("selectedFrame", selectedFrame);
    formData.append("selectedService", selectedService);
  
    submit(formData, {
      method: "post",
      action: !showUser ? "/invoice" : "/team-statistics",
    });
  };

  const setCustomStop = async (date) => {
    const stopTime = date.toISOString();
    
    setState((prevState) => ({
      ...prevState,
      timeStop: DateTime.fromISO(stopTime),
    }));
  
    const formData = new FormData();
    formData.append("startTime", state.timeStart.toISODate());
    formData.append("stopTime", stopTime);
    formData.append("accountId", accountId);
    formData.append("showUser", showUser);
    formData.append("selectedFrame", selectedFrame);
    formData.append("selectedService", selectedService);
  
    submit(formData, {
      method: "post",
      action: !showUser ? "/invoice" : "/team-statistics",
    });

  };


  const prepInvoice = async () => {
    setIsLoading(true);

    try {
      if (!showUser) {
        await get("/api/user/invoice-increment-idx");
      }
      window.print();
    } finally {
      setIsLoading(false);
    }
  };

  let DonutFlavor = state.DonutFlavor || TimeDonut;
  let BreakdownFlavor = state.BreakdownFlavor || TimeBreakdownTable;
  
  //If viewing self invoice, force choice as cost breakdown for tables
  if(!showUser) BreakdownFlavor = CostBreakdownTable;

  const user = useSelector((state) => state.auth.user);
  return (
    <div className="container">
      <div className="buffer-5vh print-only"></div>
      <div className="no-print">
        {/* Not too sure if the callbacks are correct */}
        <button
          disabled={isLoading || !entries.length}
          className="btn btn-success mb-3"
          onClick={prepInvoice}
          name="intent"
          value="post"
        >
          {showUser ? "Save as PDF" : "Download"}
        </button>
        {user.role >= 2 && <DonutFlavorPicker handleFlavor={handleDonutFlavor} />}

        {!showUser && <InvoiceWarning />}

        <InvoiceSelect
          showUser={showUser}
          handleSelect={handleSelect}
          handleTeamMemberSelect={handleTeamMemberSelect}
          handleServiceSelect={handleServiceSelect}
        />

        {state.customTimeSelectionEnabled && (
          <div className="card card-body center">
          <div className="row center">
            <div className="col">
              <Calendar
                value={state.start}
                maxDate={state.stop}
                onChange={setCustomStart}
              />
            </div>
            <div className="col">
              <Calendar
                value={state.stop}
                onChange={setCustomStop}
              />
            </div>
          </div>
          </div>
        )}
      </div>
      {entries.length > 0 ? (
        <>
          <div className="row mb-3">
            <div className="card card-body">
              {!showUser && (
                <InvoiceInfo
                  timeStart={state.timeStart}
                  timeStop={state.timeStop}
                  now={state.now}
                />
              )}

              <div className={showUser ? "row" : "row no-print"}>
                <div className="col">
                  <div className="card card-body" style={{ height: "510px" }}>
                    <HourHistogram />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="card card-body">
                    <DonutFlavor
                      keyname="project"
                      height="420px"
                      colors={colors}
                      topNBuckets={topNBuckets}
                    />
                  </div>
                </div>

                <div className="col">
                  <div className="card card-body">
                    <DonutFlavor
                      keyname="organization"
                      fancykeyname="site"
                      height="420px"
                      colors={colors}
                      topNBuckets={topNBuckets}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="card card-body">
                    <DonutFlavor
                      keyname="department"
                      fancykeyname="depeartment"
                      height="420px"
                      colors={colors}
                      topNBuckets={topNBuckets}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="card card-body">
                    <TotalInvoice />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row  mb-3">
            <div className="card card-body">
              <BreakdownFlavor
                keyname={"organization"}
              />
            </div>
          </div>

          <div className="row">
            <div className="card card-body">
              <OldTasksTable
                date={state.now}
                entries={entries}
                entryType="invoice-task"
              />
            </div>
          </div>
        </>
      ) : (
        <span>You have no recorded activity for the selected period.</span>
      )}
    </div>
  );
}
