export default function InvoiceWarning() {
  return (
    <div
      className="alert alert-warning alert-dismissible fade show"
      role="alert"
    >
      <strong>Warning!</strong> If your invoice doesn't look correct when you
      click "download", try changing the print parameters as such:
      <ul>
        <li>Destination: "Save as PDF"</li>
        <li>Pages: All</li>
        <li>Layout: Landscape</li>
        <li>More Settings:</li>
        <ul>
          <li> Paper Size: A4</li>
          <li> Margins: None</li>
          <li> Scale:100%/Default</li>
          <li> Background Graphics: enabled </li>
        </ul>
      </ul>
    </div>
  );
}
