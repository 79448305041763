import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { DateTime } from "luxon";

import { roundTo } from "../../util/helpers";
import { useLoaderData } from "react-router-dom";

export default function CostDonut({
  fancykeyname,
  keyname,
  height,
  colors,
  topNBuckets,
}) {
  const { entries } = useLoaderData();
  const [data, setData] = useState(null);

  const update = () => {
    // In any case, the parent sends an array of entries, either for a given user or for the whole team
    // The job is the following:
    // 1. Determine what the Doughnut will be displaying: projects, tasks, departments, etc.
    // 2. Iterate over all entries to find all unique task names, project names, etc., and aggregate their frequencies
    // 3. Retrieve the unique keys, up to a given MAX number, with the top frequencies
    // 4. Aggregate all other keys in an "other category"
    // 5. Construct the data object that will be passed to the Doughnut

    // Determine the category by which we are going to aggregate data; "project" by default
    let categoryName = keyname || "project";

    if (categoryName === "organization") {
      categoryName = "department.org";
    }

    // Initialize an empty object that will be populated with unique keys and their respective aggregated durations
    const entryCosts = {};

    // Start iterating over the array of entries passed from the parent
    entries.forEach((entry) => {
      const uniqueKey = getUniqueKey(entry, categoryName);
      const cost = calculateCost(entry);
      entryCosts[uniqueKey] = roundTo((entryCosts[uniqueKey] || 0) + cost, 2);
    });

    // Select top N buckets and calculate the "other" category if necessary
    const { topLabels, topCosts, otherCost } = selectTopCosts(
      entryCosts,
      topNBuckets,
    );
    if (otherCost > 0) {
      topLabels.push("other");
      topCosts.push(otherCost);
    }

    // Update state with new data
    const data = prepareChartData(topLabels, topCosts, keyname);

    setData(data);
  };

  useEffect(() => {
    update();
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      ArcElement,
      Title,
      Tooltip,
      Filler,
      Legend,
    );
  }, [entries]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Cost by " + (fancykeyname || keyname || "project"),
      },
    },
  };

  const calculateCost = (entry) => {
    const start = DateTime.fromISO(entry.start_time);
    const end = DateTime.fromISO(entry.end_time);
    const duration = end.diff(start, "hours");
    const cost = duration.hours * entry.service.hourly_rate;
    return cost;
  };

  const selectTopCosts = (costs, maxCount) => {
    const entries = Object.entries(costs).sort((a, b) => b[1] - a[1]);
    const topEntries = entries.slice(0, maxCount);
    const otherEntries = (entries.length > maxCount)? entries.slice(maxCount) : [];
    const otherCost = otherEntries.reduce((sum, [, cost]) => sum + cost, 0);

    return {
      topLabels: topEntries.map(([key]) => key),
      topCosts: topEntries.map(([, cost]) => cost),
      otherCost,
    };
  };

  const getUniqueKey = (entry, categoryName) => {
    let uniqueKey = entry[categoryName]?.name;

    const categoryToArray = categoryName.split(".");
    if (categoryToArray.length !== 1) {
      const oneLevelDown = categoryToArray[0];
      const twoLevelsDown = categoryToArray[1];
      uniqueKey = entry[oneLevelDown][twoLevelsDown]["name"];
    }

    return uniqueKey;
  };

  const prepareChartData = (labels, data, key) => {
    return {
      labels,
      datasets: [
        {
          fill: true,
          label: `Cost by ${key || "project"}`,
          data,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: colors.slice(0, labels.length),
        },
      ],
    };
  };

  return data && <Doughnut options={options} data={data} height={height} />;
}
