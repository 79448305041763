

import React from "react";


class OffCanvasElement extends React.Component {
    render() {
        return (
          <li className="nav-item offcanvas-li d-flex">
            <img className="offcanvas-img" src={this.props.img}></img>
            <a className="nav-link active" aria-current="page" href={this.props.href}>{this.props.text}</a>
          </li>
        )
    }
}

export default OffCanvasElement;