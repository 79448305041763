import React from 'react';
import { withAuthentication } from '../wrappers';
import moment from 'moment';
class TeamInvoice extends React.Component {
  constructor(props) {
    super(props);

  }

  

  currencyToSymbol(currency) {
    if (currency == 0) return "$"
    if (currency == 1) return "€"
    if (currency == 2) return "£"
  }

  componentDidMount(){
        
  }

  render() {
    Object.values(this.props.data).forEach(data=>{
      let freelancer = data.freelancer
      let tasks = data.tasks
      let total = moment.duration(0);
      tasks.forEach(task=>{
        let start = moment(task.start_time)
        let stop = moment(task.end_time)
        let duration = moment.duration(stop.diff(start))
        total.add(duration)
      })
      this.props.data[freelancer.id]["total_hours"] = total;

    })
        let breakdowns = Object.values(this.props.data).map(data=>{
      let total_float = moment.duration(data.total_hours).asHours()

      let cost = (total_float * data.freelancer.rate).toFixed(2)
      let total_str = moment.duration(data.total).format("hh:mm:ss")
      let symbol = this.currencyToSymbol(data.freelancer.currency)
      return (<>
        <div className="row">
          <div className="card"></div>
        <span><b>Rate: </b> {data.freelancer.rate}{symbol} per hour</span>
        
        </div>
        <div className="row">
        <span><b>Total Hours: </b> {total_float.toFixed(2)} hours. ({total_str})</span>
        </div>

        <div className="row">
        <span><b>Total cost: </b> {cost}{symbol}</span>
        </div>

        <div className="row">
          <span><span style={{fontSize:"32px", color:"#008800"}}>$</span> <b style={{fontSize:"24px",color:"#22aa22"}}>{cost}</b></span>
        </div>
        </>)
    })
    let total_str = this.props.total ? this.props.total.format("hh:mm:ss") : ":(";
    let rate = this.props.user.rate
    let symbol = this.currencyToSymbol(this.props.user.currency)
    let total = moment.duration(this.props.total).asHours()
    let cost = (total * rate).toFixed(2)
    return (
      <div style={{height:"470px", overflowY:"scroll", overflowX:"hidden"}}>
        {breakdowns}
      </div>
    )
  }
}

export default withAuthentication(TeamInvoice);